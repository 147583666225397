import React from "react";
import { Link } from "react-router-dom";

const ItemTwoLinesLink: React.FC<{
    title: string;
    content: string;
    link: string;
    nodivider?: boolean;
    tags?: Array<any>;
    target?: string;
    majeurId?: string;
}> = (props) => {
    return (
        <div className="item_two_line_link max-w-md overflow-hidden">
            {props.target === "_blank" ? (
                <a
                    href={props.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="layout-center-start column"
                >
                    <h6 className="text-lg font-semibold">{props.title}</h6>
                    {props.tags && props.tags.length > 0 && (
                        <div className="tags_container flex gap-2 mt-2">
                            {props.tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className="tag px-2 py-1 text-sm text-white rounded"
                                    style={{ backgroundColor: tag.color.value }}
                                >
                                    {tag.label}
                                </div>
                            ))}
                        </div>
                    )}
                    <p className="text-sm mt-1">{props.content}</p>
                </a>
            ) : (
                <Link to={props.link} className="layout-center-start column">
                    <h6 className="text-lg font-semibold">{props.title}</h6>
                    {props.tags && props.tags.length > 0 && (
                        <div className="tags_container flex gap-2 mt-2">
                            {props.tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className="tag px-2 py-1 text-sm text-white rounded"
                                    style={{ backgroundColor: tag.color.value }}
                                >
                                    {tag.label}
                                </div>
                            ))}
                        </div>
                    )}
                    <p className="text-sm mt-1">{props.content}</p>
                </Link>
            )}
            {!props.nodivider && <div className="divider border-t mt-2"></div>}
        </div>
    );
};

export default ItemTwoLinesLink;
