import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    attachPaymentMethod,
    createCustomer,
    createSetupIntent,
} from "../../../_api/Stripe";
import { Button } from "../../../_shared/Button";

const SubscriptionForm = (props) => {
    const { companyName, currentUser } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const { addToast } = useToasts();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");

        const ibanElement = elements.getElement(IbanElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "sepa_debit",
            sepa_debit: ibanElement,
            billing_details: {
                name: companyName,
                email: currentUser?.crg_infos?.email || companyName,
            },
        });
        let clientSecret = "";
        await createSetupIntent().then((resp) => {
            clientSecret = resp.data.setupIntentClientSecret;
        });

        if (error) {
            setErrorMessage(error.message);
            console.error("Error creating payment method:", error);
            return;
        }
        const { setupIntent, error: confirmError } =
            await stripe.confirmSepaDebitSetup(clientSecret, {
                payment_method: paymentMethod.id,
            });

        if (confirmError) {
            console.error("Erreur confirmation SetupIntent:", confirmError);
            return;
        }

        if (currentUser?.crg_infos?.email) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}api/stripe/customer/createCRG`, // Assurez-vous que l'URL est correcte
                    {
                        payment_method_id: paymentMethod.id,
                        companyName: companyName,
                        paymentMethod: paymentMethod,
                        email: currentUser?.crg_infos?.email || companyName,
                    }
                );
                setSuccessMessage("Moyen de paiement enregistré !");
                console.log("Subscription created:", response.data);
            } catch (error) {
                setErrorMessage("Erreur lors de la création de l'abonnement.");
                console.error("Subscription error:", error);
            }
        } else {
            let customerId = currentUser?.abonnement.stripeId;
            if (!customerId) {
                createCustomer().then((resp) => {
                    attachPaymentMethod({
                        paymentMethodId: paymentMethod.id,
                    }).then((resp) => {
                        const clientSecret =
                            resp.data.data.setupIntentClientSecret;
                    });
                });
            } else {
                try {
                    attachPaymentMethod({
                        paymentMethodId: paymentMethod.id,
                    }).then((resp) => {
                        addToast("Moyen de paiement ajouté avec succès !", {
                            appereance: "success",
                        });
                    });
                } catch (err) {
                    addToast("Erreur lors de l'ajout du moyen de paiement:", {
                        appereance: "success",
                    });
                    console.log(err);
                }
            }
        }
    };

    return (
        <>
            <div className="mt-4 max-w-md p-6 bg-white rounded-lg shadow-md">
                <>
                    <h2 className="text-2xl font-bold text-center mb-4">
                        Changer de moyen de paiement SEPA
                    </h2>
                    <form>
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-1">
                                IBAN :
                            </label>
                            <IbanElement
                                options={{
                                    supportedCountries: ["SEPA"], // Assurez-vous de spécifier les pays pris en charge
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "#333",
                                            "::placeholder": {
                                                color: "#aaa",
                                            },
                                        },
                                        invalid: {
                                            color: "#fa755a",
                                        },
                                    },
                                }}
                            />
                        </div>
                        {errorMessage && (
                            <div className="text-red-500 text-sm mb-4">
                                {errorMessage}
                            </div>
                        )}
                        {successMessage && (
                            <div className="text-green-500 text-sm mb-4">
                                {successMessage}
                            </div>
                        )}
                        <Button
                            type={"empty"}
                            disabled={!stripe}
                            name={"Enregistrer le moyen de paiement"}
                            action={(e) => {
                                handleSubmit(e);
                            }}
                        />
                    </form>
                </>
            </div>
        </>
    );
};

export default SubscriptionForm;
