import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getSimulateurTarif } from "../../../_api/InvoicingCRG";
import { Button } from "../../../_shared/Button";
import { InputForm } from "../../../_shared/InputField";

export const Simulateur = () => {
    const [patrimoine, setPatrimoine] = useState(0);
    const [revenu, setRevenu] = useState(0);
    const [tarif, setTarif] = useState(null);
    const { addToast } = useToasts();
    const history = useHistory();

    const getTarif = () => {
        const payload = {
            wealth: patrimoine,
            revenus: revenu,
        };

        getSimulateurTarif(addToast, payload).then((resp) => {
            setTarif((Math.ceil(resp.data * 100) / 100).toFixed(2));
        });
    };

    return (
        <div className="flex flex-col md:flex-row h-screen bg-gray-100">
            {/* Image Section */}
            <div className="md:w-1/2 w-full relative h-64 md:h-auto">
                <img
                    src={"/imgs/logo_crg.webp"}
                    alt="Chateau"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-6 text-white">
                    <h1 className="text-2xl md:text-4xl font-bold">
                        Simulateur de facturation de Contrôle de CRG
                    </h1>
                    <p className="text-sm md:text-lg mt-2">
                        Par Accel Gerancia
                    </p>
                </div>
            </div>

            {/* Form Section */}
            <div className="md:w-1/2 w-full flex justify-center items-center p-4">
                {tarif ? (
                    <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center text-center p-6 text-white">
                        <div className="p-4 bg-blue-100 border border-blue-300 rounded-lg">
                            <p className="text-lg font-semibold text-blue-700">
                                Tarification estimée : {tarif} €
                            </p>
                        </div>
                        <p className="text-sm md:text-lg mt-4">
                            Vous n'êtes pas encore client ? Aucun souci !
                            Utilisez notre simulateur pour obtenir une
                            estimation rapide et fiable, sans engagement.
                        </p>
                        <div className="flex flex-col md:flex-row justify-center mt-4">
                            <Button
                                action={() => {
                                    setTarif(null);
                                    setRevenu(0);
                                    setPatrimoine(0);
                                }}
                                name={"Nouvelle simulation"}
                                className="mb-2 md:mb-0 md:mr-2 px-6 py-2 text-sm md:text-lg bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition-all"
                            />
                            <Button
                                action={() => history.push("/enregistrement")}
                                name={"Faciliter vos contrôles"}
                                type={"vert"}
                                className="px-6 py-2 text-sm md:text-lg bg-green-600 text-white rounded-lg hover:bg-green-500 transition-all"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white p-6 md:p-10 rounded-lg shadow-md w-full max-w-md">
                        <h3 className="text-center text-lg md:text-xl font-semibold">
                            Simulateur de facturation
                        </h3>
                        <div className="space-y-4 mt-4">
                            <InputForm
                                name="Patrimoine du protégé"
                                set={setPatrimoine}
                                value={patrimoine}
                            />
                            <InputForm
                                name="Revenu du protégé"
                                set={setRevenu}
                                value={revenu}
                            />
                        </div>
                        <div className="mt-6 flex justify-center">
                            <Button
                                action={getTarif}
                                name={"Calculer votre tarification"}
                                className="w-full md:w-auto px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition-all"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
