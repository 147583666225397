import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { getContacts } from "../../../../_api/Contacts";
import { getCourts, getMjpmi } from "../../../../_api/Court";
import {
    createControl,
    editControl,
    getControl,
    uploadJudgement,
} from "../../../../_api/Mandat";
import { getUsersOfAdmin } from "../../../../_api/User";
import { Button } from "../../../../_shared/Button";
import { DefaultDropdown } from "../../../../_shared/Dropdown";
import { InputForm } from "../../../../_shared/InputField";
import { formatDate } from "../../../../constants/Utils";
import InputSwitch from "../../../layout/forms/InputSwitch";
import { useAuth } from "../../Login/auth.context";
import { DocUpload } from "../Control/Segment/DocUpload";

const mesureTypes = [
    { label: "Tutelle", value: "T" },
    { label: "Tutelle ad'hoc", value: "TADHOC" },
    { label: "Curatelle ad'hoc", value: "CADHOC" },
    { label: "Co-tutelle", value: "CT" },
    { label: "Co-curatelle", value: "CC" },
    { label: "Curatelle simple", value: "CS" },
    { label: "Curatelle renforcée", value: "CR" },
    { label: "Mandat spécial", value: "MS" },
    { label: "Mesure d'accompagnement judiciaire", value: "MAJ" },
    { label: "Mandat de protection future", value: "MPF" },
    { label: "Subrogé curateur", value: "SC" },
    { label: "Subrogé tuteur", value: "ST" },
];
export const CreateMandat = () => {
    const { addToast } = useToasts();
    const { mandat_id }: { mandat_id?: string } = useParams();
    const { setCurrentUser, currentUser } = useAuth();

    const history = useHistory();
    const [tribunals, setTribunals] = useState([]);

    const [tribunal, setTribunal] = useState(null);
    const [typeMesure, setTypeMesure] = useState("T");

    const [numeroRG, setNumeroRG] = useState("");
    const [portalis, setPortalis] = useState("");
    const [judgmentDoc, setJudgmentDoc] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [monthDuration, setMonthDuration] = useState(0);

    const [firstLastName, setFirstLastName] = useState("");
    const [name, setName] = useState("");

    const [agentName, setAgentName] = useState("");
    const [agentMail, setAgentMail] = useState("");
    const [agentMobile, setAgentMobile] = useState("");
    const [agentAdress, setAgentAdress] = useState("");

    const [observations, setObservations] = useState("");
    const [isArchived, setisArchived] = useState(false);

    const [mjpmi, setMjpmi] = useState(null);
    const [mjpmis, setMjpms] = useState([]);

    const [contactsList, setContactsList] = useState([]);

    const [contactSelection, setContactSelection] = useState(null);
    const [users, setUsers] = useState([]);
    const [userMandat, setUserMandat] = useState(null);

    useEffect(() => {
        getContacts(addToast).then((resp) => {
            const formattedContacts = resp.data.flatMap((item) => {
                return item.contacts.map((contact) => {
                    let particulier =
                        contact.last_name + " " + contact.first_name;
                    let name = contact.organisme || particulier;

                    return {
                        value: contact._id, // Remplace "id" par la clé unique de ton contact
                        data: contact,
                        label: name, // Remplace "name" par la propriété à afficher
                    };
                });
            });
            setContactsList(formattedContacts);
        });

        getUsersOfAdmin(addToast).then((resp) => {
            setUsers(
                resp.data.map((user) => {
                    return {
                        value: user._id,
                        label:
                            user.profile.infos.last_name +
                            " " +
                            user.profile.infos.first_name,
                    };
                })
            );
        });
        getCourts(addToast).then((resp) => {
            let data = resp.data
                .filter((res: any) => {
                    if (currentUser.roles[0] === "judge") {
                        if (currentUser.supervising_courts.includes(res.id)) {
                            return { value: res.id, label: res.city };
                        }
                    } else {
                        return { value: res.id, label: res.city };
                    }
                })
                .map((res: { id: any; city: any }) => {
                    return { value: res.id, label: res.city };
                })
                .sort((a, b) => a.label.localeCompare(b.label));
            if (data.length > 0) {
                setTribunal(data[0].value);
            }
            setTribunals(data);
        });
        if (currentUser.roles[0] === "judge") {
            getMjpmi(addToast).then((resp) => {
                if (resp.data.length > 0) {
                    setMjpmi(resp.data[0]._id);
                }
                setMjpms(
                    resp.data.map((res) => {
                        const additionalsInfos = res.crg_infos?.limit_mandats
                            ? " (" +
                              res.total_mandats +
                              "/" +
                              res.crg_infos?.limit_mandats +
                              ")"
                            : "";
                        return {
                            value: res._id,
                            label:
                                res.profile.infos.last_name +
                                " " +
                                res.profile.infos.first_name +
                                additionalsInfos,
                        };
                    })
                );
            });
        }
        if (mandat_id) {
            getControl(addToast, mandat_id).then((resp: any) => {
                const data = resp.data;
                setAgentMail(data.tutor_mail);
                setAgentName(data.tutor_name);
                setAgentMobile(data.tutor_mobile);
                setAgentAdress(data.tutor_address);
                setisArchived(data.isArchived);
                setJudgmentDoc(data.judgment_doc);
                setMonthDuration(data.month_duration);
                setNumeroRG(data.rg_number);
                setPortalis(data.portalis_number);
                setStartDate(formatDate(new Date(data.start_period)));
                setFirstLastName(data.name);
                setUserMandat(data.user_id_attribute);
                setName(data.second_name);
                setObservations(data.observations);
                setTypeMesure(data.mesure_type);
                setTribunal(data.tribunal);
            });
        }
    }, []);

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div>
                    <Button
                        type={"empty"}
                        name={"Retour"}
                        action={() => {
                            history.push("/list_mandats");
                        }}
                    />
                </div>
                <div className="flex">
                    {users.length > 0 && (
                        <div className="m-2">
                            <DefaultDropdown
                                required={true}
                                options={users}
                                value={userMandat}
                                onChange={(e) => setUserMandat(e.target.value)}
                            />
                        </div>
                    )}

                    <div className="mt-3">
                        <InputSwitch
                            id="isArchivedProtege"
                            className="column-item-flex-1"
                            onChange={() => {
                                setisArchived(!isArchived);
                            }}
                            label="Mandats"
                            isSwitchMajeur={true}
                            onText="Archivés"
                            offText="Courants"
                            value={isArchived}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <DefaultDropdown
                    required={true}
                    name={"Tribunal"}
                    options={tribunals}
                    value={tribunal}
                    onChange={(e) => setTribunal(e.target.value)}
                />
            </div>
            {currentUser.roles[0] === "judge" && (
                <div className="mt-3">
                    <DefaultDropdown
                        required={true}
                        name={"Choisir un contrôleur"}
                        options={mjpmis}
                        value={mjpmi}
                        onChange={(e) => setMjpmi(e.target.value)}
                    />
                </div>
            )}
            <hr className="m-4" />
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro de RG"
                        set={setNumeroRG}
                        value={numeroRG}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Numéro Portalis"
                        set={setPortalis}
                        value={portalis}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Protégé : Nom et prénom"
                        set={setName}
                        value={name}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Protégé : Nom de jeune fille"
                        set={setFirstLastName}
                        value={firstLastName}
                    />
                </div>
            </div>
            <hr className="m-4" />
            <h4 className="mb-4">Informations de la mesure</h4>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début de nomination"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Durée de la mesure (mois)"
                        type="number"
                        set={setMonthDuration}
                        value={monthDuration}
                        required={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <DefaultDropdown
                        required={true}
                        name={"Type de mesure"}
                        options={mesureTypes}
                        value={typeMesure}
                        onChange={(e) => setTypeMesure(e.target.value)}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0 flex items-center">
                    <DocUpload
                        show={true}
                        value={judgmentDoc}
                        set={setJudgmentDoc}
                        title={"Jugement ou extrait de jugement"}
                        uniqueId={`doc-upload-debt-consumer2`}
                    />
                </div>
            </div>
            <hr className="m-4 mt-8" />
            <div className="flex items-center gap-4">
                <div className="flex items-center h-12">
                    <h4 className="mb-0">
                        Informations des tuteurs ou curateur
                    </h4>{" "}
                </div>
                <div className="h-12 flex items-center">
                    {" "}
                    <DefaultDropdown
                        required={true}
                        placeholder="Carnet d'adresses"
                        options={contactsList}
                        value={contactSelection}
                        onChange={(e) => {
                            const contact = contactsList.find(
                                (contact) => contact.value === e.target.value
                            );
                            console.log(contact.data.addresses);
                            setContactSelection(e.target.value);
                            setAgentName(
                                contact.data.organisme || contact.data.last_name
                            );
                            setAgentMail(
                                contact.data.emails
                                    ? contact.data.emails[0]?.email
                                    : ""
                            );
                            setAgentMobile(
                                contact.data.phones
                                    ? contact.data.phones[0]?.phone
                                    : ""
                            );
                            setAgentAdress(
                                contact.data.addresses
                                    ? contact.data.addresses[0]?.address +
                                          ", " +
                                          contact.data.addresses[0]?.zip_code +
                                          " " +
                                          contact.data.addresses[0]?.city
                                    : ""
                            );
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Nom du tuteur/mandataire"
                        set={setAgentName}
                        value={agentName}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Email du tuteur/mandataire"
                        type="email"
                        set={setAgentMail}
                        value={agentMail}
                    />
                </div>
                <div className="w-full md:w-1/3 px-3 md:mb-0">
                    <InputForm
                        name="Téléphone tuteur/mandataire"
                        set={setAgentMobile}
                        value={agentMobile}
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3 md:mb-0">
                    <InputForm
                        name="Adresse"
                        set={setAgentAdress}
                        value={agentAdress}
                    />
                </div>
            </div>
            <hr className="m-4" />
            <InputForm
                name="Observations"
                type={"textarea"}
                set={setObservations}
                value={observations}
            />
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    action={() => {
                        const payload = {
                            tribunal: parseInt(tribunal),
                            rg_number: numeroRG,
                            portalis_number: portalis,
                            start_period: new Date(startDate),
                            mesure_type: typeMesure,
                            judgment_doc: judgmentDoc,
                            month_duration: monthDuration,
                            observations: observations,
                            name: firstLastName,
                            isArchived: isArchived,
                            second_name: name,
                            tutor_name: agentName,
                            tutor_mail: agentMail,
                            tutor_mobile: agentMobile,
                            tutor_address: agentAdress,
                            user_id_attribute: userMandat,
                            mjpmi: mjpmi,
                        };

                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (
                            agentMail &&
                            agentMail.length > 0 &&
                            !regex.test(agentMail)
                        ) {
                            addToast("Votre email est invalide", {
                                appearance: "warning",
                            });
                            return;
                        }

                        if (numeroRG.length < 3 && portalis.length < 3) {
                            addToast(
                                "Votre numéro de RG ou portalis est invalide.",
                                {
                                    appearance: "warning",
                                }
                            );
                            return;
                        }

                        if (mandat_id) {
                            editControl(addToast, payload, mandat_id).then(
                                (resp) => {
                                    if (
                                        judgmentDoc &&
                                        judgmentDoc instanceof Array &&
                                        judgmentDoc.length !== 0
                                    ) {
                                        const formData = new FormData();
                                        formData.append("file", judgmentDoc[0]);
                                        uploadJudgement(
                                            addToast,
                                            formData,
                                            mandat_id
                                        ).then((resp) => {
                                            history.push(
                                                "/mandat/" + mandat_id
                                            );
                                        });
                                    } else {
                                        history.push("/mandat/" + mandat_id);
                                    }

                                    addToast("Votre mandat a été modifié.", {
                                        appearance: "success",
                                    });
                                }
                            );
                        } else {
                            createControl(addToast, payload).then((resp) => {
                                if (
                                    judgmentDoc &&
                                    judgmentDoc instanceof Array &&
                                    judgmentDoc.length !== 0
                                ) {
                                    const formData = new FormData();
                                    formData.append("file", judgmentDoc[0]);
                                    uploadJudgement(
                                        addToast,
                                        formData,
                                        resp.data._id
                                    ).then((resp) => {
                                        history.push(
                                            "/mandat/" + resp.data._id
                                        );
                                    });
                                } else {
                                    history.push("/mandat/" + resp.data._id);
                                }

                                addToast("Votre mandat a été crée.", {
                                    appearance: "success",
                                });
                                history.push("/list_mandats");
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
};
