import React from "react";
import { useHistory } from "react-router";

const ModalEvent = (props: {
    isOpen: boolean;
    setIsOpen: Function;
    events: Array<Object>;
}) => {
    const { setIsOpen, isOpen, events } = props;
    const history = useHistory();
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            {/* Bouton pour ouvrir la modal */}
            <button
                onClick={() => setIsOpen(true)}
                className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition duration-300"
            >
                Voir les événements
            </button>

            {/* Modal */}
            {isOpen && (
                <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h2 className="text-2xl font-bold mb-4">
                            Liste des événements
                        </h2>
                        <ul className="space-y-2">
                            {events.map((event: any) => {
                                const name = event.last_name
                                    ? " - " +
                                      event.last_name +
                                      " " +
                                      event.first_name
                                    : "";

                                const title = event.event + name;
                                return (
                                    <li
                                        key={event.alarm_id}
                                        onClick={() =>
                                            history.push(
                                                "/alertes/" + event.alarm_id
                                            )
                                        }
                                        className="cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 transition duration-200"
                                    >
                                        {title}
                                    </li>
                                );
                            })}
                        </ul>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                        >
                            Fermer
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalEvent;
