import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";
import { useAuth } from "./auth.context";

const Login: React.FC = () => {
    const { addToast } = useToasts();
    const { setCurrentUser } = useAuth();

    const history = useHistory();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const onKeyUp = (event: any) => {
        if (event.key === "Enter") {
            signIn();
        }
    };

    // Connexion request
    const signIn = async () => {
        request({
            addToast,
            url: "/api/login",
            method: "post",
            data: {
                user_email: email,
                user_password: password,
            },
        })
            .then(async ({ data }) => {
                // Set current logged in user in context
                setCurrentUser(data);

                sessionStorage.setItem("user", JSON.stringify(data));
                sessionStorage.setItem("userId", data._id);

                if (
                    data.roles[0] === "verificateur_crg" ||
                    data.roles[0] === "verificateur_crg_user" ||
                    data.roles[0] === "depot_crg" ||
                    data.roles[0] === "judge"
                ) {
                    history.push("/accueil");
                } else {
                    history.push("/dashboard");
                }

                addToast("Connexion réussi", { appearance: "success" });
            })
            .catch();
    };

    return (
        <>
            <div className="login layout-start-center column">
                <img
                    id="logo"
                    src="/imgs/logo-accel-gerancia-blanc.svg"
                    alt="logo"
                />
                <h1 className="welcome_message">
                    Bienvenue sur notre logiciel
                </h1>
                <form className="layout-start-center column">
                    <div className="my-input-container">
                        <label htmlFor="email">Email :</label>
                        <input
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="my-input"
                            type="email"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="my-input-container">
                        <label htmlFor="password">Mot de passe :</label>
                        <input
                            onKeyUp={onKeyUp}
                            name="password"
                            className="my-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            placeholder="Mot de passe"
                            required
                        />
                    </div>
                    <div className="forgot layout-start-center row">
                        {/* <div className="forgot layout-start-center row">
                    <p className="small-text"><Link to="/register">Créer un compte</Link></p>
                </div> */}
                        <div className="forgot layout-end-center row">
                            <p className="small-text">
                                <Link to="/forgot_password">
                                    Mot de passe oublié ?
                                </Link>
                            </p>
                        </div>
                    </div>
                    <button type="button" onClick={() => signIn()}>
                        Se connecter
                    </button>
                </form>
            </div>
        </>
    );
};
export default Login;
