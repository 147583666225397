import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AlertMessage } from "../../../../_shared/AlertMessage";
import { yearsTable } from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import IRessource from "../../../../interfaces/IRessource";
import { request } from "../../../../utils/axios-utils";
import Divider from "../../../layout/Divider";
import Loader from "../../../layout/Loader";
import Select from "../../../layout/forms/Select";
import FinancialDetailsTable from "./DetailsFinanciableTable";
import FinancialTable from "./TotalFinancial";

const RessourcesMajeur: React.FC<{
    majeurId: string;
    majeur: IProtectedAdult;
}> = (props) => {
    const [RessourcesCat, setRessourcesCat] = useState<string>("RevenusImp");
    const addToast = useToasts();

    const [ressources, setRessources] = useState<IRessource>(null);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [yearSelected, setYearSelected] = useState<number>(
        new Date().getFullYear() - 1
    );

    useEffect(() => {
        if (!ressources || ressources?.annee !== yearSelected) {
            /* GET ressources by YEAR */
            actualiseRessources(yearSelected);
        }
    }, [yearSelected]);

    const actualiseRessources = (year: number) => {
        request({
            addToast,
            url: `/api/majeurs/ressources/${props.majeurId}/${year}`,
        })
            .then(({ data }) => {
                setRessources(data);
            })
            .catch();
    };

    const yearOptions: Array<any> = yearsTable();

    const totalEpargne =
        (ressources?.assurance_foret ?? 0) +
        (ressources?.epargne_actions ?? 0) +
        (ressources?.co_developpement ?? 0) +
        (ressources?.epargne_logement ?? 0) +
        (ressources?.LDD ?? 0) +
        (ressources?.livret_jeune ?? 0) +
        (ressources?.PEP ?? 0) +
        (ressources?.livret_A ?? 0) +
        (ressources?.LEP ?? 0) +
        (ressources?.LES ?? 0) +
        (ressources?.capital_extension ?? 0) +
        (ressources?.codebis ?? 0) +
        (ressources?.cel ?? 0) +
        (ressources?.pel ?? 0) +
        (ressources?.csl ?? 0) +
        (ressources?.compte_a_terme ?? 0) +
        (ressources?.Perp ?? 0) +
        (ressources?.livret_bleu ?? 0);

    const totalBnpr =
        (ressources?.capitaux_non_productif_revenus ?? 0) * 0.03 +
        (ressources?.locative_immeubles_batis ?? 0) +
        (ressources?.valeur_locative_immeubles_non_batis ?? 0) +
        (ressources?.assurance_vie ?? 0) * 0.03 +
        (ressources?.interessement_participation ?? 0) * 0.03;

    const totalAllRI =
        (ressources?.aah ?? 0) +
        (ressources?.allocation_personnes_agees ?? 0) +
        (ressources?.minimum_vieillesse ?? 0) +
        (ressources?.revenu_solidarite ?? 0) +
        (ressources?.prime_activite ?? 0) +
        (ressources?.revenus_hors_france ?? 0);

    const totalRI =
        (ressources?.revenus_fonciers_bruts ?? 0) +
        (ressources?.benefices_industriels_commerciaux ?? 0) +
        (ressources?.rem_gerant_associes ?? 0) +
        (ressources?.benefices_exploitation_agricole ?? 0) +
        (ressources?.plus_values_cession ?? 0) +
        (ressources?.capitaux_mobiliers ?? 0) +
        (ressources?.bene_profession_non_commercial ?? 0) +
        (ressources?.traitement_salaires_pensions ?? 0);

    const bigTotal = totalRI + totalEpargne + totalBnpr + totalAllRI;

    const rows = [
        {
            label: "Revenus Fonciers bruts (déclaration 2044)",
            sub_cat: "Revenus immobiliers - Revenus foncier",
            value: ressources?.revenus_fonciers_bruts,
        },
        {
            label: "Bénéfices industriels et commerciaux",
            sub_cat: "Revenus - BIC",
            value: ressources?.benefices_industriels_commerciaux,
        },
        {
            label: "Rémunérations allouées aux gérants et associés",
            sub_cat: "Revenus - Gérant Associés",
            value: ressources?.rem_gerant_associes,
        },
        {
            label: "Bénéfices de l'exploitation agricole",
            sub_cat: "Revenus - Fermage",
            value: ressources?.benefices_exploitation_agricole,
        },
        {
            label: "Traitements, salaires, pensions et rentes viagères",
            sub_cat: "Revenus - Salaires, rentes et pensions",
            value: ressources?.traitement_salaires_pensions,
        },
        {
            label: "Bénéfices des professions non commerciales",
            sub_cat: "Revenus - BNC",
            value: ressources?.bene_profession_non_commercial,
        },
        {
            label: "Revenus des capitaux mobiliers",
            sub_cat: "Revenus mobiliers - Intérêts, Coupons et dividendes",
            value: ressources?.capitaux_mobiliers,
        },
        {
            label: "Plus-values de cession à titre onéreux de biens ou de droits de toute nature",
            sub_cat:
                "Autres ressources - Vente titre, vente immobilière, vente mobilier",
            value: ressources?.plus_values_cession,
        },
    ];

    const rowsEpargne = [
        {
            label: "Livret A",
            sub_cat: "Somme des soldes des Livret A",
            value: ressources?.livret_A || 0,
        },
        {
            label: "LEP",
            sub_cat: "Somme des soldes LEP",
            value: ressources?.LEP || 0,
        },
        {
            label: "LES",
            sub_cat: "Somme des soldes LES",
            value: ressources?.LES || 0,
        },
        {
            label: "PEP",
            sub_cat: "Somme des soldes PEP",
            value: ressources?.PEP || 0,
        },
        {
            label: "Livret jeune",
            sub_cat: "Somme des soldes des livrets jeunes",
            value: ressources?.livret_jeune || 0,
        },
        {
            label: "LDD",
            sub_cat: "Somme des soldes des LDD",
            value: ressources?.LDD || 0,
        },
        {
            label: "Epargne logement",
            sub_cat: "Somme des soldes des comptes épargne logement",
            value: ressources?.epargne_logement || 0,
        },
        {
            label: "Co développement",
            sub_cat: "Somme des soldes des comptes co-développement",
            value: ressources?.co_developpement || 0,
        },
        {
            label: "PEA",
            sub_cat: "Somme des soldes des PEA",
            value: ressources?.epargne_actions || 0,
        },
        {
            label: "Assurance Forêt",
            sub_cat: "Somme des soldes des Assurance Forêt",
            value: ressources?.assurance_foret || 0,
        },
        {
            label: "Capital extension",
            sub_cat: "Somme des soldes des comptes Capital extension",
            value: ressources?.capital_extension || 0,
        },
        {
            label: "Codebis",
            sub_cat: "Somme des soldes Codebis",
            value: ressources?.codebis || 0,
        },
        {
            label: "CEL",
            sub_cat: "Somme des soldes CEL",
            value: ressources?.cel || 0,
        },
        {
            label: "PEL",
            sub_cat: "Somme des soldes PEL",
            value: ressources?.pel || 0,
        },
        {
            label: "CSL",
            sub_cat: "Somme des soldes CSL",
            value: ressources?.csl || 0,
        },
        {
            label: "Compte à terme",
            sub_cat: "Somme des soldes Compte à terme",
            value: ressources?.compte_a_terme || 0,
        },
        {
            label: "PERP",
            sub_cat: "Somme des soldes PERP",
            value: ressources?.Perp || 0,
        },
        {
            label: "Livret Bleu",
            sub_cat: "Somme des soldes Livret Bleu",
            value: ressources?.livret_bleu || 0,
        },
    ];

    const rowsBienNP = [
        {
            label: "Capitaux non productif de revenu *",
            sub_cat: "Capitaux non productif de revenu",
            value: ressources?.capitaux_non_productif_revenus || 0,
            additionalValue: ressources?.capitaux_non_productif_revenus
                ? ressources.capitaux_non_productif_revenus * 0.03 || 0
                : 0,
        },
        {
            label: "Valeur locative, Immeubles batis",
            sub_cat: "Valeur locative, Immeubles batis",
            value: ressources?.locative_immeubles_batis || 0,
        },
        {
            label: "Valeur locative immeuble non batis",
            sub_cat: "Valeur locative immeuble non batis",
            value: ressources?.valeur_locative_immeubles_non_batis || 0,
        },
        {
            label: "Assurance vie *",
            sub_cat: "Assurance vie",
            value: ressources?.assurance_vie || 0,
            additionalValue: ressources?.assurance_vie
                ? ressources.assurance_vie * 0.03 || 0
                : 0,
        },
        {
            label: "Interessement participative *",
            sub_cat: "Interessement participative",
            value: ressources?.interessement_participation || 0,
            additionalValue: ressources?.interessement_participation
                ? ressources.interessement_participation * 0.03 || 0
                : 0,
        },
    ];

    const rowsAide = [
        {
            label: "A.A.H.",
            sub_cat: "Allocations - AAH",
            value: ressources?.aah || 0,
        },
        // {
        //     label: "Allocation personnes agées",
        //     sub_cat: "Allocation - APA",
        //     value: ressources?.allocation_personnes_agees || 0,
        // },
        {
            label: "Minimum viellesse",
            sub_cat: "Allocation - ASPA",
            value: ressources?.minimum_vieillesse || 0,
        },
        {
            label: "Revenu solidarité",
            sub_cat: "Allocation - RSA",
            value: ressources?.revenu_solidarite || 0,
        },
        {
            label: "Prime d'activité",
            sub_cat: "Allocation - Prime d'activité",
            value: ressources?.prime_activite || 0,
        },
        {
            label: "Revenu hors France",
            sub_cat: "Revenu - Revenu hors France",
            value: ressources?.revenus_hors_france || 0,
        },
    ];

    return (
        <div className="ressources_majeur">
            <div className="subBar_actions sticky top-90 layout-space-between-center row">
                <span className="subBar_actions_title">Les ressources</span>
                <div className="subBar_actions_item">
                    {!props.majeur?.archived && (
                        <Link
                            to={`/update_ressources/${props.majeurId}/${yearSelected}`}
                            className="row"
                        >
                            <i className="material-icons">edit</i>
                        </Link>
                    )}
                </div>
            </div>
            <div className="flex flex-wrap justify-between items-center space-y-4 sm:space-y-0 sm:space-x-6 p-4">
                {/* Select Year */}
                <div className="w-full sm:w-auto">
                    <Select
                        id="year_option"
                        value={yearSelected}
                        options={yearOptions}
                        className="p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => {
                            setYearSelected(e.target.value);
                            actualiseRessources(e.target.value);
                        }}
                    />
                </div>

                {/* Button Group */}
                <div className="flex space-x-4 sm:space-x-6">
                    {["RevenusImp", "Epargne", "BiensNP", "Allocations"].map(
                        (cat) => (
                            <button
                                key={cat}
                                className={`px-4 py-2 rounded-lg font-semibold transition duration-300 transform hover:scale-105 focus:outline-none ${
                                    RessourcesCat === cat
                                        ? "bg-blue-500 text-white"
                                        : "bg-gray-200 text-gray-700 hover:bg-blue-100"
                                }`}
                                onClick={() => setRessourcesCat(cat)}
                            >
                                {cat === "RevenusImp" && "Revenus Imposables"}
                                {cat === "Epargne" && "Epargne"}
                                {cat === "BiensNP" &&
                                    "Biens non productifs de revenus"}
                                {cat === "Allocations" &&
                                    "Allocations + Revenus internationaux"}
                            </button>
                        )
                    )}
                </div>
            </div>

            {isLoading && <Loader />}

            {!isLoading && (
                <>
                    <AlertMessage
                        type={"warning"}
                        message={
                            <p>
                                [EN COURS DE CONSTRUCTION] : A partir de 2025,
                                le calcul des ressources est automatique à
                                partir de la catégorisation de vos opérations et
                                du solde de vos comptes. Ce formulaire est mis à
                                jour 2x dans la journée.
                                <p>
                                    Attention à bien vérifier vos sous
                                    catégories et vos types de comptes.
                                </p>{" "}
                                <b>
                                    Il reste à remplir les biens non productifs
                                    de revenus.
                                </b>
                            </p>
                        }
                    />
                    {RessourcesCat === "RevenusImp" && (
                        <FinancialDetailsTable
                            rows={rows}
                            additionalValue={false}
                            secondTitle="Calcul automatique - Sous catégorie des opérations"
                        />
                    )}
                    {RessourcesCat === "Epargne" && (
                        <FinancialDetailsTable
                            rows={rowsEpargne}
                            additionalValue={false}
                            secondTitle={
                                "Calcul automatique - Solde au 31/12/" +
                                yearSelected
                            }
                        />
                    )}
                    {RessourcesCat === "BiensNP" && (
                        <FinancialDetailsTable
                            rows={rowsBienNP}
                            additionalValue={true}
                            secondTitle={null}
                        />
                    )}
                    {RessourcesCat === "Allocations" && (
                        <FinancialDetailsTable
                            rows={rowsAide}
                            additionalValue={false}
                            secondTitle="Calcul automatique - Sous catégorie des opérations"
                        />
                    )}
                    <Divider />
                    <FinancialTable
                        totalAllRI={totalAllRI}
                        totalBnpr={totalBnpr}
                        totalEpargne={totalEpargne}
                        totalRI={totalRI}
                        bigTotal={bigTotal}
                    />
                </>
            )}
        </div>
    );
};
export default RessourcesMajeur;
