import React from "react";
import IUser from "../../../interfaces/IUser";
import Divider from "../../layout/Divider";
import CardList from "../../layout/lists/CardList";
import SepaList from "../../layout/lists/SepaList";
import Loader from "../../layout/Loader";
import ModalFactory from "../../layout/modals/ModalFactory";
import Row from "../../layout/Row";

const Activated: React.FC<{
    stripeReady: boolean;
    subscribeWithSepa: Function;
    updateSepa: Function;
    modifyPaymentMean: string;
    cards: any;
    sepas: any;
    sourcesAvailable: boolean;
    user: IUser;
}> = (props) => {
    return (
        <>
            <div>
                <div className="row layout-space-around-center">
                    <p className="activated">Votre Abonnement est activé !</p>
                </div>
                <Divider />

                <Row
                    style={{ marginTop: "1em", minHeight: 125 }}
                    activated={false}
                >
                    {(props.sourcesAvailable &&
                        (props.modifyPaymentMean === "_NO_" ||
                            props.modifyPaymentMean === "_DONE_") && (
                            <div>
                                {props.cards && props.cards.length > 0 && (
                                    <CardList cards={props.cards} />
                                )}
                                {props.sepas && props.sepas.length > 0 && (
                                    <SepaList sepas={props.sepas} />
                                )}
                                <div
                                    style={{ marginTop: 20 }}
                                    className="row layout-space-between-center"
                                >
                                    <ModalFactory
                                        activated={true}
                                        title="Changer votre moyen de paiement"
                                        stripeReady={props.stripeReady}
                                        subscribeWithSepa={() =>
                                            props.subscribeWithSepa()
                                        }
                                        updateSepa={(sepa: any) =>
                                            props.updateSepa(sepa)
                                        }
                                    />
                                </div>
                            </div>
                        )) || <Loader noMargin />}
                </Row>
            </div>
        </>
    );
};
export default Activated;
