import mammoth from "mammoth//mammoth.browser.min.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { uploadDoc } from "../../../_api/S3";
import {
    capitalizeFirst,
    modules,
    momentUpdateLocale,
} from "../../../constants/Utils";
import ITemplate from "../../../interfaces/ITemplate";
import { request } from "../../../utils/axios-utils";
import SubBar from "../../layout/SubBar";
import InputComponent from "../../layout/forms/InputComponent";
import InputSwitch from "../../layout/forms/InputSwitch";
import Select from "../../layout/forms/Select";

const CreateUpdate: React.FC = () => {
    const [S3FolderUser, setS3FolderUser] = useState<string>(null);
    const [templateMail, setTemplateMail] = useState<ITemplate | null>(null);
    const [templateType, setTemplateType] = useState<string>("Courrier");
    const [templateTitle, setTemplateTitle] = useState<string>("");
    const [useDocument, setUseDocument] = useState<boolean>(false);

    const [fileS3, setFileS3] = useState<any>(null);
    const [text, setText] = useState("");
    const { templateId }: { templateId?: string } = useParams();
    const { addToast } = useToasts();

    const history = useHistory();

    momentUpdateLocale();

    useEffect(() => {
        // If it is a modification, it loads the alert to modify.
        if (templateId && !templateMail) {
            request({ addToast, url: `/api/mails/${templateId}` })
                .then(({ data }) => {
                    setTemplateType(data.type);
                    setTemplateTitle(data.label);
                    setText(
                        data.body.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
                    );
                    setTemplateMail(data);
                })
                .catch();
        }
        if (!S3FolderUser) {
            request({ addToast, url: `/api/user/settings` })
                .then(({ data }) => {
                    setS3FolderUser(data.S3_folder_user);
                })
                .catch();
        }
    }, [templateMail]);

    /**
     * Save/Create a template mail.
     */
    const createTemplateInMongo = (url: string, payload: any) => {
        request({ addToast, url: url, method: "post", data: { payload } })
            .then((data) => {
                // The data has been saved.
                addToast(
                    `Le courrier type a bien été ${
                        templateId ? "modifiée" : "créé"
                    }`,
                    { appearance: "success" }
                );
                if (templateId) {
                    history.push(`/list_templates_mail/${templateId}/lecture`);
                } else {
                    history.push("/list_templates_mail");
                }
            })
            .catch();
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Tab") {
            event.preventDefault();
            const quill = document.querySelector(".ql-editor");
            const selection = window.getSelection();

            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const textNode = range.startContainer;

                range.insertNode(document.createTextNode("\t"));
                range.setStartAfter(textNode);
            }
        }
    };

    /**
     * Save/Create a template mail.
     */
    const submitTemplateMail = async () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const url = templateId
            ? `/api/mails/${templateId}/edit`
            : `/api/mails/create/${templateMail}`;

        let payload;

        if (!fileS3) {
            payload = {
                type: templateType,
                label: capitalizeFirst(templateTitle),
                body: text,
            };

            createTemplateInMongo(url, payload);
        } else {
            const fileObject = {
                type: fileS3.type,
                size: fileS3.size,
                name: fileS3.name,
                mimetype: fileS3.type,
            };

            let randomString = (Math.random() + 1).toString(36).substring(7);

            const fileName =
                S3FolderUser +
                "/courriers/" +
                randomString +
                "-" +
                fileObject.name.replace(/[^a-zA-Z0-9.]/gi, "-");

            const formData = new FormData();
            formData.append("file", fileS3);
            formData.append("key", fileName);

            await uploadDoc(formData)
                .then((response) => {
                    fileObject.url =
                        process.env.endpointS3Paris +
                        process.env.bucketNameS3Paris +
                        "/" +
                        fileName;
                    fileObject.key = fileName;
                    const payload = {
                        type: templateType,
                        label: capitalizeFirst(templateTitle),
                        file: fileObject,
                    };

                    createTemplateInMongo(url, payload);
                })
                .catch((resp) => {
                    console.log(resp);
                });
        }
    };

    /**
     * Lauch template mail download
     */
    const triggerUploadTemplateMail = () => {
        document.getElementById("templateMailInput").click();
    };

    const fileValidation = (file: any) => {
        // Allowing only .docx file type
        var allowedExtensions = /(\.docx)$/i;
        if (!allowedExtensions.exec(file.name)) return false;
        else return true;
    };
    /**
     * Transform file into html
     */
    const putFileInState = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            if (fileValidation(event.target.files[0])) {
                const fileS3 = event.target.files[0];
                var fr = new FileReader();
                fr.onload = () => {
                    var data = fr.result;
                    const a = mammoth
                        .convertToHtml({ arrayBuffer: data })
                        .then((html: any) => {
                            setText(html.value);
                            setUseDocument(true);
                        });
                };
                fr.readAsArrayBuffer(fileS3);
                setFileS3(fileS3);
                addToast(`Téléchargement du fichier réussi !`, {
                    appearance: "success",
                });
            } else {
                addToast(
                    `Téléchartgement impossible. Le fichier n'est pas au format " .docx "`,
                    { appearance: "error" }
                );
            }
        } else {
            addToast(`Erreur lors du téléchargement du fichier`, {
                appearance: "error",
            });
        }
    };

    const handleChange = (value) => {
        setText(value);
    };

    return (
        <>
            {!templateId && !templateMail && (
                <SubBar
                    title={"Ajouter le courrier type"}
                    back="/list_templates_mail"
                />
            )}

            {templateId && templateMail && (
                <SubBar
                    title={
                        templateType !== "Requête"
                            ? `Modifier le courrier type`
                            : `Modifier la requête type`
                    }
                    back={"/list_templates_mail/" + templateId + "/lecture"}
                />
            )}

            <div className="update_template_mail">
                <div className="page_content full-width layout-center-center column">
                    <form className="update_mail_zone layout-center-center full-width column">
                        <div className="mail_container">
                            <div className="row">
                                <Select
                                    id={`${templateId}`}
                                    className="flex-1"
                                    value={templateType}
                                    label="Type"
                                    required
                                    onChange={(e) =>
                                        setTemplateType(e.target.value)
                                    }
                                    options={[
                                        {
                                            label: "Courrier",
                                            value: "Courrier",
                                            key: "1",
                                        },
                                        {
                                            label: "Requête",
                                            value: "Requête",
                                            key: "2",
                                        },
                                    ]}
                                />

                                <div className="flex005" />

                                <InputComponent
                                    id="label"
                                    type="text"
                                    className="flex-1"
                                    label="Titre"
                                    required
                                    value={templateTitle || ""}
                                    onChange={(e) =>
                                        setTemplateTitle(e.target.value)
                                    }
                                    maxLength={50}
                                />
                            </div>

                            {!useDocument && (
                                <div>
                                    {
                                        <ReactQuill
                                            theme="snow"
                                            value={text}
                                            onChange={(value) => {
                                                handleChange(value);
                                            }}
                                            modules={modules}
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#000",
                                                minHeight: 300,
                                            }}
                                            // onKeyDown={handleKeyDown} // Associer l'événement au clavier
                                        />
                                    }
                                </div>
                            )}

                            {!fileS3 && !templateId && (
                                <div className="row layout-center-center isTemplate">
                                    <span>
                                        Télécharger votre modèle de courrier au
                                        format .docx
                                    </span>
                                    <div className="flex005" />
                                    <i
                                        onClick={triggerUploadTemplateMail}
                                        className="material-icons pointer layout-center-center"
                                    >
                                        cloud_upload
                                    </i>
                                    <input
                                        id="templateMailInput"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e) => putFileInState(e)}
                                    />
                                </div>
                            )}
                            {fileS3 && (
                                <div>
                                    <div className="row layout-center-center isTemplate">
                                        {fileS3.name}
                                    </div>

                                    <InputSwitch
                                        id="label"
                                        className="column-item-flex-1"
                                        label="Utiliser le docx"
                                        value={useDocument}
                                        onChange={() =>
                                            setUseDocument(!useDocument)
                                        }
                                    />
                                </div>
                            )}
                            <div className="layout-end-center row edited text_zone">
                                édité le :{" "}
                                {(templateMail &&
                                    moment(templateMail.created_at).format(
                                        "L"
                                    )) ||
                                    moment().format("L")}
                            </div>
                        </div>
                        <button
                            onClick={() => submitTemplateMail()}
                            className="valid-button"
                            style={{ marginTop: 15 }}
                        >
                            Valider
                        </button>
                    </form>

                    {!fileS3 && (
                        <div className="dynamic_fields">
                            Champs dynamique disponibles : <br /> <br />
                            <div
                                className="possibilities"
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                {`
// Protégé
{civiliteProtege}{nomUsageProtege}{nomNaissanceProtege}{nomCompletProtege}{nomCompletEtCiviliteProtege}{prenomProtege}{adresseCompleteProtege}{adresseProtege}{villeProtege}{codePostalProtege}{nSecuProtege}{dateNaissanceProtege}{dateDecesProtege}{lieuNaissanceProtege}{telephoneProtege}{emailProtege}{emailProtegeAccelGerancia}{situationFamillialeProtege}

// protection
{natureProtectProtege}{periodeProtectProtege}{protectionProtectProtege}{lieuDeVieProtege}{raisonDeSortieProtege}

// Contrat
{labelContrat}{numeroContrat}

// Mesure
{dateDeNomination}{dateDeMesure}{dateDeFinDeMesure}{dateRevision}{dureeMesure}{rgMesure}{nbPortalis}{labelTribunal}{numeroCabinetTribunal}

// Destinataire
{civiliteDestinataire}{nomDestinataire}{prenomDestinataire}{organismeDestinataire}{adresseCompleteDestinataire}{adresseDestinataire}{villeDestinataire}{codePostalDestinataire}

// Mandataire
{civiliteMJPM}{prenomMJPM}{nomMJPM}{nomCompletEtCiviliteMJPM}{nomCompletMJPM}{adresseMJPM}{villeMJPM}{codePostalMJPM}{adresseCompleteMJPM}{siretMJPM}{nAgrementMJPM}{emailMJPM}{mobileMJPM}{telephoneMJPM}{titreMJPM}

// Autres
{date} {dateLettre}
                  `}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdate;
