import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { checkHashFromLetter, getControlFromUsers } from "../../../_api/Mandat";
import { Button } from "../../../_shared/Button";
import { InputForm } from "../../../_shared/InputField";
import { useAuth } from "../Login/auth.context";
import ControlSelection from "./ControlSelection";

export const ListControlCRG = (props) => {
    const { isProtected } = props;
    const { addToast } = useToasts();
    const history = useHistory();

    const [hash, setHash] = useState();
    const [validHash, setValidHash] = useState(false);
    const [currentControls, setCurrentControls] = useState([]);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (isProtected) {
            getControlFromUsers(addToast, currentUser.crg_infos.mandat_id).then(
                (resp) => {
                    setValidHash(true);
                    setCurrentControls(resp.data);
                }
            );
        }
    }, []);

    const isValidHash = () => {
        checkHashFromLetter(addToast, hash).then((resp) => {
            setValidHash(resp.data.validated);
            setCurrentControls(resp.data.controls);
        });
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {!isProtected && (
                <div className="w-1/2 relative">
                    <img
                        id="logo"
                        src={"/imgs/logo_crg.webp"}
                        alt="Chateau"
                        className="w-full h-full object-cover"
                    />

                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-10 text-white">
                        <h1 className="text-4xl font-bold">
                            Contrôle de Compte Rendu de Gestion (CRG)
                        </h1>
                    </div>
                </div>
            )}
            <ControlSelection
                isProtected={isProtected}
                validHash={validHash}
                currentControls={currentControls}
                setCurrentControls={setCurrentControls}
            />
            {!isProtected && !validHash && (
                <div className="w-1/2 flex justify-center items-center">
                    <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                        <div className="space-y-4">
                            <h3 className="text-center">
                                Contrôle de Compte Rendu de Gestion (CRG)
                            </h3>
                            <InputForm
                                name="Code fourni par courrier"
                                set={setHash}
                                value={hash}
                            />
                            <div className="flex justify-end">
                                <Button
                                    action={() => isValidHash()}
                                    name={"Accéder à mon contrôle"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
