import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createDepot } from "../../../../_api/Depot";
import { getControl } from "../../../../_api/Mandat";
import { Button } from "../../../../_shared/Button";
import { InputForm } from "../../../../_shared/InputField";
import InputSwitch from "../../../layout/forms/InputSwitch";

export const CreateControl = () => {
    const { addToast } = useToasts();

    const { mandat_id }: { mandat_id?: string } = useParams();

    const history = useHistory();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isSendingEmail, setIsSendingEmail] = useState(true);
    const [mandat, setMandat] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (mandat_id) {
            getControl(addToast, mandat_id).then((resp) =>
                setMandat(resp.data)
            );
        }
    }, [mandat_id]);

    return (
        <div className="p-4">
            <Button
                type={"empty"}
                name={"Retour"}
                action={() => {
                    history.push("/list_controls");
                }}
            />

            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Début du controle"
                        type="date"
                        set={setStartDate}
                        value={startDate}
                        required={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <InputForm
                        name="Fin de controle"
                        type="date"
                        set={setEndDate}
                        value={endDate}
                        required={true}
                    />
                </div>
            </div>
            {mandat && mandat.tutor_mail && (
                <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        {" "}
                        <InputSwitch
                            id="isSendingEmail"
                            className="column-item-flex-1"
                            onChange={() => setIsSendingEmail(!isSendingEmail)}
                            label="Envoi courrier d'information + Jugement"
                            isSwitchMajeur={false}
                            onText="Oui"
                            offText="Non"
                            value={isSendingEmail}
                        />
                    </div>
                </div>
            )}

            <hr className="m-4" />
            <div className="flex justify-end">
                <Button
                    type={"empty"}
                    name={"Enregistrer"}
                    isDisabled={isLoading}
                    action={() => {
                        setIsLoading(true);
                        const payload = {
                            start_period: new Date(startDate),
                            end_period: new Date(endDate),
                            control_id: mandat_id,
                            isSendingEmail: isSendingEmail,
                        };

                        createDepot(addToast, payload).then((resp) => {
                            addToast("Création du controle réalisée.", {
                                appearance: "success",
                            });
                            setIsLoading(false);
                            history.push("/mandat/" + mandat_id);
                        });
                    }}
                />
            </div>
        </div>
    );
};
