import React, { useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { createAlarm } from "../../../_api/Alarm";
import { DefaultDropdown } from "../../../_shared/Dropdown";
import { InputForm } from "../../../_shared/InputField";
import { capitalizeFirst } from "../../../constants/Utils";
import InputDatePicker from "../../layout/forms/InputDatePicker";

const CreateEventModal = (props: {
    isOpen: boolean;
    setIsOpen: Function;
    majeurs: Array<any>;
    refreshAlarms: Function;
}) => {
    const { setIsOpen, isOpen, refreshAlarms, majeurs } = props;

    const [label, setLabel] = useState("");
    const [notes, setNotes] = useState("");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [majeur, setMajeur] = useState(null);
    const [isTracking, setIsTracking] = useState(false);

    const { addToast } = useToasts();

    const history = useHistory();

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            {/* Modal */}
            {isOpen && (
                <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h2 className="text-2xl font-bold mb-4">
                            Ajouter un évènement
                        </h2>
                        <InputForm
                            name="Intitulé"
                            value={label}
                            set={setLabel}
                            placeholder={"Evènement"}
                            required={true}
                        />
                        <DefaultDropdown
                            name={"Majeur"}
                            options={[
                                {
                                    label: "Sélectionner un majeur (optionnel)",
                                    value: null,
                                },
                                ...majeurs.map((majeur) => ({
                                    label: `${majeur.last_name} ${majeur.first_name}`,
                                    value: majeur._id,
                                })),
                            ]}
                            value={majeur}
                            onChange={(e) => setMajeur(e.target.value)}
                        />
                        <InputForm
                            name="Notes"
                            value={notes}
                            type={"textarea"}
                            set={setNotes}
                            placeholder={"..."}
                        />
                        <InputDatePicker
                            id="start_date_alarm"
                            required={true}
                            label="Début de l'évènement"
                            inputDate={startDate}
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            onChange={(date: Date) => {
                                setStartDate(date);
                            }}
                        />
                        <hr className="m-2" />
                        <InputDatePicker
                            id="end_date_alarm"
                            required={true}
                            inputDate={endDate}
                            label="Fin de l'évènement"
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            onChange={(date: Date) => {
                                setEndDate(date);
                            }}
                        />
                        <hr className="m-2" />
                        <InputForm
                            name="Enregistrer dans le suivi"
                            type="checkbox"
                            set={setIsTracking}
                            value={isTracking}
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="mr-4 mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                            >
                                Fermer
                            </button>
                            <button
                                onClick={() => {
                                    if (!startDate || !endDate) {
                                        addToast(
                                            "Il vous faut remplir toutes les dates"
                                        );
                                        return;
                                    }

                                    if (!label) {
                                        addToast(
                                            "Il vous faut remplir l'intitulé."
                                        );
                                        return;
                                    }
                                    const payload = {
                                        label: capitalizeFirst(label.trim()),
                                        date_alarm: startDate,
                                        date_final: endDate,
                                        done: false,
                                        repetition: "_AUCUNE_",
                                        sending_email: false,
                                        notes: notes,
                                        majeur_id: majeur,
                                        type: "EVENT",
                                        isTracking: isTracking,
                                    };
                                    createAlarm(addToast, payload).then(
                                        (resp) => {
                                            refreshAlarms();
                                        }
                                    );
                                    setIsOpen(false);
                                }}
                                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
                            >
                                Ajouter
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateEventModal;
