import React, { useState } from "react";
import { useAuth } from "../../Login/auth.context";
import { Info } from "./Info";
import { Invoice } from "./Invoice";
import { Users } from "./Users";

const menu = [
    {
        label: "Informations de compte",
        id: "infos",
    },
    {
        label: "Utilisateurs",
        id: "users",
    },
    {
        label: "Facturation",
        id: "facturation",
    },
];

export const SettingsCRG = () => {
    const { currentUser } = useAuth();
    const [activeTab, setActiveTab] = useState("infos");

    const handleTabChange = (tab: React.SetStateAction<string>) => {
        setActiveTab(tab);
    };

    return (
        <div className="p-2">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    {menu.map((tab) => {
                        return (
                            <li className="me-2">
                                <a
                                    href="#"
                                    onClick={() => handleTabChange(tab.id)}
                                    className={
                                        activeTab === tab.id
                                            ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                            : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                    }
                                >
                                    {tab.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {activeTab === "facturation" && <Invoice />}
            {activeTab === "users" && <Users />}
            {activeTab === "infos" && currentUser.roles[0] !== "judge" && (
                <Info />
            )}
        </div>
    );
};
