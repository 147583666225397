import { request } from "../utils/axios-utils";

export const updateSetting = (addToast: any, type: any, fileMongo: any) => {
    return request({
        addToast,
        url: `/api/user/settings/update`,
        method: "post",
        data: { type: type, file: fileMongo },
    });
};

export const updateCRGUser = (addToast: any, user_id: string, payload: any) => {
    return request({
        addToast,
        url: `/api/user/modify/` + user_id,
        method: "post",
        data: payload,
    });
};

export const getSettings = (addToast: any) => {
    return request({ addToast, url: `/api/user/settings` });
};

export const getSettingsCRG = (addToast: any, depot_id: string) => {
    return request({ addToast, url: "api/user/settings/" + depot_id });
};

export const getUsersOfAdmin = (addToast) => {
    return request({
        addToast,
        url: "api/users/crg",
        method: "get",
    });
};

export const setUserScanner = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: `/api/user/settings/scanner`,
        method: "post",
        data: { scanner: payload },
    });
};
