import { request } from "../utils/axios-utils";

const namespace = "/api/stripe";

export const createCustomer = () => {
    return request({
        url: namespace + `/customer`,
        method: "post",
        data: {},
    });
};

export const createSubscription = () => {
    return request({
        url: namespace + `/subscription`,
        method: "post",
        data: {},
    });
};

export const attachPaymentMethod = (payload) => {
    return request({
        url: namespace + `/attach/payment`,
        method: "post",
        data: payload,
    });
};

export const createSetupIntent = () => {
    return request({
        url: namespace + `/setupIntent`,
        method: "get",
    });
};
