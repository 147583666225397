import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getControls } from "../../../../_api/Mandat";
import { Button } from "../../../../_shared/Button";
import InputSwitch from "../../../layout/forms/InputSwitch";
import Loader from "../../../layout/Loader";
import { useAuth } from "../../Login/auth.context";

export const ListMandats = () => {
    const { addToast } = useToasts();
    const { setCurrentUser, currentUser } = useAuth();

    const history = useHistory();
    const [controls, setControls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredControls, setFilteredControls] = useState([]);
    const [archivedProtege, setisArchived] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const loadControls = () => {
        getControls(addToast).then((resp) => {
            setControls(resp.data);
            const filteredData = resp.data.filter(
                (item) => item.isArchived === archivedProtege
            );
            setFilteredControls(filteredData);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        loadControls();
    }, []);

    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTribunal, setSelectedTribunal] = useState("");

    const filteredData = filteredControls.filter((item) => {
        const fullName = `${item.second_name} ${item.name || ""}`.toLowerCase();
        return (
            fullName.includes(searchTerm.toLowerCase()) &&
            (selectedCategory ? item.status === selectedCategory : true) &&
            (selectedTribunal ? item.tribunal_name === selectedTribunal : true)
        );
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const uniqueTribunals = [
        ...new Set(controls.map((item) => item.tribunal_name)),
    ].sort();

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div>
                <div className="flex justify-end m-2">
                    <InputSwitch
                        id="isArchivedProtege"
                        className="column-item-flex-1"
                        onChange={() => {
                            const filteredData = controls.filter(
                                (item) => item.isArchived === !archivedProtege
                            );
                            setisArchived(!archivedProtege);
                            setFilteredControls(filteredData);
                        }}
                        label="Mandats"
                        isSwitchMajeur={true}
                        onText="Archivés"
                        offText="Courants"
                        value={archivedProtege}
                    />
                    <Button
                        type={"empty"}
                        name={"Ajouter un mandat"}
                        action={() => {
                            history.push(`/create_mandat`);
                        }}
                    />
                </div>
                <div className="p-2">
                    <input
                        type="text"
                        placeholder="Rechercher un protégé..."
                        className="p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        value={selectedTribunal}
                        onChange={(e) => setSelectedTribunal(e.target.value)}
                        className="p-2 border rounded-md"
                    >
                        <option value="">Tous les tribunaux</option>
                        {uniqueTribunals.map((tribunal, index) => (
                            <option key={index} value={tribunal}>
                                {tribunal}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    {isLoading && (
                        <div className="flex items-center justify-center h-64">
                            <Loader />
                        </div>
                    )}
                    {!isLoading && (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    {currentUser.roles[0] === "judge" && (
                                        <th scope="col" className="px-6 py-3">
                                            Contrôleur
                                        </th>
                                    )}
                                    <th scope="col" className="px-6 py-3">
                                        Numéro de RG
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Tribunal
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Portalis
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Majeur
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Représentant
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => {
                                    if (
                                        currentUser.roles[0] === "judge" ||
                                        (currentUser.roles[0] !== "judge" &&
                                            item.status !==
                                                "PENDING_MJPM_VALIDATION")
                                    ) {
                                        return (
                                            <tr
                                                key={index}
                                                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                                {currentUser.roles[0] ===
                                                    "judge" && (
                                                    <td className="px-6 py-4">
                                                        {item.controler_name}
                                                    </td>
                                                )}
                                                <td className="px-6 py-4">
                                                    {item.rg_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.tribunal_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.portalis_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.name
                                                        ? item.second_name +
                                                          " " +
                                                          item.name
                                                        : item.second_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.tutor_name ||
                                                        "Non renseigné"}
                                                </td>

                                                <td className="px-6 py-4">
                                                    <Link
                                                        to={
                                                            "/mandat/" +
                                                            item._id
                                                        }
                                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                                    >
                                                        Voir
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                {currentItems.length === 0 && (
                                    <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                        <td colSpan={6}>
                                            Il n'y a actuellement aucun controle
                                            de réaliser.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {currentPage > 3 && (
                        <>
                            <button
                                onClick={() => paginate(1)}
                                className="px-4 py-2 mx-1 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-blue-700"
                            >
                                1
                            </button>
                            <span className="px-2 py-2 text-sm text-gray-500">
                                ...
                            </span>
                        </>
                    )}

                    {Array.from({ length: totalPages }, (_, index) => {
                        const page = index + 1;
                        // Affiche les 3 premières pages et les 3 dernières pages
                        if (
                            page <= 3 ||
                            page > totalPages - 3 ||
                            (page >= currentPage - 1 && page <= currentPage + 1)
                        ) {
                            return (
                                <button
                                    key={page}
                                    onClick={() => paginate(page)}
                                    className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                        currentPage === page
                                            ? "bg-blue-500"
                                            : "bg-gray-700"
                                    } rounded-md hover:bg-blue-700`}
                                >
                                    {page}
                                </button>
                            );
                        }
                        return null;
                    })}

                    {currentPage < totalPages - 3 && (
                        <>
                            <span className="px-2 py-2 text-sm text-gray-500">
                                ...
                            </span>
                            <button
                                onClick={() => paginate(totalPages)}
                                className="px-4 py-2 mx-1 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-blue-700"
                            >
                                {totalPages}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
