export const ToolbarMajeurItems = [
    {
        _id: 'infos',
        title: 'Infos',
        icon: 'info',
        path: 'infos',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'mesure',
        title: 'Mesure',
        icon: 'chrome_reader_mode',
        path: 'mesure',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'social',
        title: 'Droits/Contrats',
        icon: 'import_contacts',
        path: 'social',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'mobImmo',
        title: 'Biens/Dettes',
        icon: 'location_city',
        path: 'mobImmo',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'comptes',
        title: 'Comptes',
        icon: 'account_balance',
        path: 'accounts',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'tester', 'FAMILLE', 'CRG_VERIF']
    },
    {
        _id: 'ressources',
        title: 'Ressources',
        icon: 'euro_symbol',
        path: 'ressources',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'tester', 'FAMILLE', 'CRG_VERIF']
    },
    {
        _id: 'suivis',
        title: 'Suivis',
        icon: 'assignment',
        path: 'suivis',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'alarms',
        title: 'Alertes',
        icon: 'notifications',
        path: 'alarms',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester']
    },
    {
        _id: 'contacts',
        title: 'Contacts',
        icon: 'people_outline',
        path: 'contacts',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester', 'FAMILLE']
    },
    {
        _id: 'mails',
        title: 'Courriers',
        icon: 'mail',
        path: 'courriers',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'ACCOUNTANT', 'tester']
    },
    {
        _id: 'cloud',
        title: 'Cloud',
        icon: 'cloud',
        path: 'cloud',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'ACCOUNTANT', 'tester', 'FAMILLE']
    },
    {
        _id: 'stats',
        title: 'Statistiques',
        icon: 'trending_up',
        path: 'stats',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester']
    },
    {
        _id: 'settings',
        title: 'Paramètres',
        icon: 'settings',
        path: 'settings',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester']
    },
];
