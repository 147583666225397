import { jsPDF } from "jspdf";
import "jspdf/dist/polyfills.es.js";
import { forEach } from "lodash";
import moment from "moment";
import wrap from "word-wrap";
import {
    defaultMajeurFields,
    majeurFields,
    mesureFields,
    momentUpdateLocale,
    returnDateFormated,
} from "../../../constants/Utils";
import IAdvisors from "../../../interfaces/IAdvisors";

const pdfMargin = 10;
const pdfInMM = 210; // Width of A4 in mm
const pageCenter = pdfInMM / 2;
const inlineHeight = 8; //Space between lines
const maxY = 280;
const borderPaddingX = 2;
const borderPaddingYTop = 7;
const borderPaddingYBottom = 7;

// Wrap a string in a fixed width
function w(value: string, width: any = "100") {
    return wrap(value, { width: parseInt(width) });
}

// Check if the value...
function checkValueString(value: String) {
    return value && isString(value);
}

// ... is a String
function isString(value: String) {
    return typeof value === "string" || value instanceof String;
}

// Check if we need to add a new page before to print an other text
function checkPage(doc: jsPDF, y: number) {
    if (y >= maxY) {
        doc.addPage();
        return 20;
    }

    return y;
}

momentUpdateLocale();

// Draw a " - "
function drawBorder(
    doc: jsPDF,
    x: number,
    y: number,
    width: number,
    height: number
) {
    doc.setLineWidth(0.3);
    doc.rect(
        x - borderPaddingX,
        y - borderPaddingYTop,
        width,
        height + borderPaddingYBottom
    );
}

// Print and center a text
function printCenteredText(
    doc: jsPDF,
    text: string,
    y: number,
    marginLeft = 0
) {
    const dim = doc.getTextDimensions(text);
    doc.text(text, pageCenter - dim.w / 2 + marginLeft, y);
}

// Return dimensions of a text
function getTextDimensions(doc: jsPDF, text: string) {
    const dim = doc.getTextDimensions(text);

    const numberLineBreak = (text.match(/\n/g) || []).length + 1;

    return { w: dim.w, h: dim.h * numberLineBreak };
}

// Print a field in the Pdf
function printField(
    doc: jsPDF,
    name: string,
    value: string,
    y: number,
    marginLeft = 0,
    maxWidth = pdfInMM - marginLeft
) {
    const currentLineY = checkPage(doc, y);

    doc.setFont(undefined, "bold");

    const dim = doc.getTextDimensions(name);

    let padding = 0;
    if (name) {
        doc.text(name, marginLeft + pdfMargin, currentLineY);
        padding = dim.w;
    }

    doc.setFont(undefined, "normal");

    const text = w(value, maxWidth - padding);
    doc.text(text, pdfMargin + marginLeft + padding, currentLineY);
    const dimValue = getTextDimensions(doc, text);

    return { w: dim.w + dimValue.w, h: dimValue.h };
}

// Print in the pdf datas from an Object
function printObjectFields(
    doc: jsPDF,
    fields: any,
    object: any,
    userFields: any,
    y: number,
    inlineH: number,
    columnNumber = 2
) {
    var formats = [moment.ISO_8601, "MM/DD/YYYY  :)  HH*mm*ss"];

    let currentColumn = 0;
    let lineBreak = true;
    let x = 0;
    let currentLineY = y;
    let result = { w: 0, h: 0 };

    Object.keys(fields)
        .filter(
            (f) =>
                f !== "civility" &&
                f !== "first_name" &&
                f !== "last_name" &&
                f !== "familly_name" &&
                f !== "notes"
        )
        .forEach((field) => {
            if (userFields.indexOf(field) === -1) {
                return false;
            }

            if (
                !lineBreak &&
                currentColumn !== 0 &&
                currentColumn % columnNumber === 0
            ) {
                currentLineY += inlineH / 2 + result.h;
                x = 0;
                lineBreak = true;
            }

            let value = object[field] || "";
            if (moment(value, formats, true).isValid()) {
                value = moment(value).format("L");
            }

            if (checkValueString(value)) {
                let maxWidth = pdfInMM / columnNumber - 45;
                if (x === 0) {
                    maxWidth = pdfInMM / columnNumber;
                }

                result = printField(
                    doc,
                    `${fields[field]}:`,
                    value,
                    currentLineY,
                    x,
                    maxWidth
                );
                x += pdfInMM / columnNumber;
                currentColumn++;
                lineBreak = false;
            }
        });

    return { currentLineY, lineBreak };
}

// Return Lastname and phone number if the Bank have an advisor
function getBankInfo(advisor: IAdvisors) {
    if (!advisor) {
        return "";
    }

    return `${advisor.last_name}: ${advisor.phone}`;
}

// Check if "fieldname" exists in fields from the majeur and if it contain something
function enabledField(
    fieldName: string,
    fields: any,
    object: any,
    isArray?: boolean
) {
    if (!isArray) {
        return fields.indexOf(fieldName) !== -1 && object[fieldName];
    }

    return (
        fields.indexOf(fieldName) !== -1 &&
        object[fieldName] &&
        object[fieldName].length
    );
}

// Print in the pdf datas from an array of Object
function printArrayOfObjects(
    doc: jsPDF,
    fieldName: string,
    fields: any,
    majeur: any,
    ids: any,
    y: number,
    getLabelFct: any,
    getValueFct: any
) {
    let currentLineY = y;

    if (fieldName == "contacts") {
        if (!enabledField(fieldName, fields, majeur, false)) {
            return currentLineY;
        }
    } else {
        if (!enabledField(fieldName, fields, majeur, true)) {
            return currentLineY;
        }
    }

    let values: any = null;
    if (typeof majeur[fieldName] == "object") {
        if (fieldName == "contacts") {
            values =
                majeur[fieldName] &&
                Object.values(majeur[fieldName]).filter(
                    (v: any) => v && ids[v._id] != false
                );
        } else {
            values =
                majeur[fieldName] &&
                Object.values(majeur[fieldName]).filter(
                    (v: any) => v && ids[v._id]
                );
        }
    } else {
        values =
            majeur[fieldName] &&
            majeur[fieldName].filter((v: any) => v && ids[v._id]);
    }

    if (!values || !values.length) {
        return currentLineY;
    }

    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.setTextColor(33, 150, 243);
    doc.text(majeurFields[fieldName], pdfMargin, currentLineY);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);

    let result = { w: 0, h: 0 };

    values.forEach((object: any) => {
        currentLineY += inlineHeight - result.h;
        currentLineY = checkPage(doc, currentLineY);
        result = printField(
            doc,
            `${getLabelFct(object)}:`,
            getValueFct(object),
            currentLineY,
            0,
            pdfInMM - 100
        );

        currentLineY += result.h;

        if (fieldName == "contacts") {
            const address = object.addresses ? object.addresses : [];

            if (address && address.length) {
                currentLineY += inlineHeight / 2;
                address.forEach((a: any) => {
                    result = printField(
                        doc,
                        `- ${a.label || "Lieu"}: `,
                        `${a.address + " " + a.zip_code + " " + a.city}`,
                        currentLineY,
                        pdfMargin / 2
                    );
                    currentLineY += result.h + inlineHeight / 2;
                    currentLineY = checkPage(doc, currentLineY);
                });
            }
        }

        if (fieldName === "socials") {
            if (object.end_date) {
                currentLineY += inlineHeight / 2;
                const endDate: string = returnDateFormated(object.end_date);
                printField(
                    doc,
                    "- Date d'échéance : ",
                    endDate,
                    currentLineY,
                    pdfMargin / 2
                );
                currentLineY += result.h + inlineHeight / 2;
                currentLineY = checkPage(doc, currentLineY);
            }
        }

        if (fieldName === "contracts") {
            if (object.end_date) {
                currentLineY += inlineHeight / 2;

                const endDate: string = returnDateFormated(object.end_date);
                printField(
                    doc,
                    "- Date d'échéance : ",
                    endDate,
                    currentLineY,
                    pdfMargin / 2
                );
                currentLineY += result.h + inlineHeight / 2;
                currentLineY = checkPage(doc, currentLineY);
            }
        }

        const phones =
            (object.contact && object.contact.phones) || object.phones;
        if (phones && phones.length) {
            currentLineY += inlineHeight / 2;
            phones.forEach((p: any) => {
                result = printField(
                    doc,
                    `- ${p.label || "Téléphone"}: `,
                    `${p.phone}`,
                    currentLineY,
                    pdfMargin / 2
                );
                currentLineY += result.h + inlineHeight / 2;
                currentLineY = checkPage(doc, currentLineY);
            });
        }

        currentLineY += result.h;
    });

    currentLineY += inlineHeight * 2;

    currentLineY = checkPage(doc, currentLineY);

    return currentLineY;
}

// Generate the pdf from the "majeur" filtered by "ids" selected in the modal
export function generateFicheAdministrative(
    majeur: any,
    fields = defaultMajeurFields,
    ids: { [unit: string]: string } = {}
) {
    let currentLineY = 20;

    // Initializing Pdf
    const doc = new jsPDF("p", "mm", "a4");

    doc.setFontSize(20);
    doc.setFont(undefined, "bold");
    doc.setTextColor(33, 150, 243);
    printCenteredText(doc, "Fiche administrative", currentLineY);
    doc.setTextColor(0, 0, 0);
    currentLineY += inlineHeight;

    doc.setFontSize(14);
    printCenteredText(
        doc,
        w(
            `${
                enabledField("civility", fields, majeur) ? majeur.civility : ""
            } ${
                enabledField("last_name", fields, majeur)
                    ? majeur.last_name
                    : ""
            } ${
                enabledField("familly_name", fields, majeur) &&
                majeur.last_name &&
                majeur.familly_name &&
                majeur.familly_name !== majeur.last_name
                    ? `née ${majeur.familly_name} `
                    : ""
            }${
                enabledField("first_name", fields, majeur)
                    ? majeur.first_name
                    : ""
            }`
        ),
        currentLineY
    );

    currentLineY += inlineHeight;

    const birth_date: string = majeur.birth_date
        ? returnDateFormated(majeur.birth_date)
        : "Non communiquée.";

    printCenteredText(doc, "Né(e) le : " + birth_date, currentLineY);

    currentLineY += inlineHeight * 2;

    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.setTextColor(33, 150, 243);
    doc.text("Informations:", pdfMargin, currentLineY);
    doc.setTextColor(0, 0, 0);

    currentLineY += inlineHeight;

    doc.setFontSize(10);

    if (enabledField("tribunal", fields, majeur) && ids[majeur.tribunal._id]) {
        majeur.tribunalString = majeur.tribunal.label;
        const field = "tribunalString";
        fields.push(field);
        majeurFields[field] = "Tribunal";
    }

    if (enabledField("phones", fields, majeur, true)) {
        majeur.phones.forEach((phone: any) => {
            if (!ids[phone._id]) {
                return false;
            }
            const field = `tel_${phone.label}`;
            majeur[field] = phone.phone;
            fields.push(field);
            majeurFields[field] = phone.label;
        });
    }

    if (enabledField("emails", fields, majeur, true)) {
        majeur.emails.forEach((email: any) => {
            if (!ids[email._id]) {
                return false;
            }
            const field = `email_${email.label}`;
            majeur[field] = email.email;
            fields.push(field);
            majeurFields[field] = email.label;
        });
    }

    let result: any = printObjectFields(
        doc,
        majeurFields,
        majeur,
        fields,
        currentLineY,
        inlineHeight
    );

    currentLineY = result.currentLineY;
    if (!result.lineBreak) {
        currentLineY += inlineHeight * 2;
    } else {
        currentLineY += inlineHeight;
    }
    currentLineY = checkPage(doc, currentLineY);

    if (enabledField("mesure", fields, majeur)) {
        doc.setFontSize(16);
        doc.setFont(undefined, "bold");
        doc.setTextColor(33, 150, 243);
        doc.text("Mesure:", pdfMargin, currentLineY);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        currentLineY += inlineHeight;

        result = printObjectFields(
            doc,
            mesureFields,
            majeur.mesure,
            fields,
            currentLineY,
            inlineHeight
        );

        currentLineY = result.currentLineY;
        if (!result.lineBreak) {
            currentLineY += inlineHeight * 2;
        } else {
            currentLineY += inlineHeight;
        }
        currentLineY = checkPage(doc, currentLineY);
    }

    currentLineY = printArrayOfObjects(
        doc,
        "addresses",
        fields,
        majeur,
        ids,
        currentLineY,
        (address: any) => address.label || "Adresse",
        (address: any) =>
            `${address.address}, ${address.city} ${address.zip_code}`
    );

    currentLineY = checkPage(doc, currentLineY);

    currentLineY = printArrayOfObjects(
        doc,
        "contracts",
        fields,
        majeur,
        ids,
        currentLineY,
        (contract: any) => contract.nature || "",
        (contract: any) =>
            `${contract.numero} ${contract.contact.organisme || ""} ${
                contract.contact.phone || ""
            }`
    );

    currentLineY = checkPage(doc, currentLineY);

    currentLineY = printArrayOfObjects(
        doc,
        "socials",
        fields,
        majeur,
        ids,
        currentLineY,
        (social: any) => social.nature || "",
        (social: any) =>
            `${social.numero || ""} ${social.contact.organisme || ""}`
    );

    currentLineY = checkPage(doc, currentLineY);

    currentLineY = printArrayOfObjects(
        doc,
        "mobiliers",
        fields,
        majeur,
        ids,
        currentLineY,
        (mobilier: any) => mobilier.label || "",
        (mobilier: any) =>
            `${mobilier.estimation ? `${mobilier.estimation} euros` : ""} ${
                mobilier.notes || ""
            }`
    );

    currentLineY = printArrayOfObjects(
        doc,
        "immobiliers",
        fields,
        majeur,
        ids,
        currentLineY,
        (immobilier: any) => immobilier.label || "",
        (immobilier: any) =>
            `${
                immobilier.estimation ? `${immobilier.estimation} euros` : ""
            }  ${immobilier.address || ""} ${immobilier.city || ""} ${
                immobilier.zip_code || ""
            } ${immobilier.notes || ""}`
    );

    currentLineY = checkPage(doc, currentLineY);

    currentLineY = printArrayOfObjects(
        doc,
        "accounts",
        fields,
        majeur,
        ids,
        currentLineY,
        (account: any) => account.type || "",
        (account: any) =>
            `${account.number || "Pas de type"} ${account.bank_label || ""} ${
                account.bank.phone || ""
            } ${account.bank.email || ""} ${getBankInfo(account.bank.advisor)}`
    );

    currentLineY = checkPage(doc, currentLineY);

    currentLineY = printArrayOfObjects(
        doc,
        "contacts",
        fields,
        majeur,
        ids,
        currentLineY,
        (contact: any) => contact.profession || "Contact",
        (contact: any) =>
            `${contact.civility || ""} ${contact.last_name || ""} ${
                contact.first_name || ""
            }`
    );

    currentLineY = checkPage(doc, currentLineY);

    if (fields.indexOf("notes") !== -1 && majeur.notes) {
        doc.setFontSize(16);
        doc.setFont(undefined, "bold");
        doc.setTextColor(33, 150, 243);
        doc.text("Notes:", pdfMargin, currentLineY);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        currentLineY += inlineHeight;
        currentLineY = checkPage(doc, currentLineY);
        result = printField(
            doc,
            "",
            majeur.notes,
            currentLineY,
            0,
            pdfInMM - 110
        );
        currentLineY += result.h;
    }

    // Return the PDF with it NAME to put him or not in a zipArchive later
    const data: { [unit: string]: any } = {
        fileName:
            "fiche_admin_" +
            majeur.last_name +
            "_" +
            majeur.first_name +
            ".pdf",
        document: doc,
    };

    return data;
}

export function generateFicheAdministrativeHTML(
    majeur: any,
    fields: any,
    ids: any = {}
): string {
    let htmlContent = `
    <html>
        <head>
            <style>
                .section {
                    margin-bottom: 20px;
                }
            </style>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 20px;">
    `;

    htmlContent += `<h1 style="color: #2196F3; text-align: center;">Fiche administrative</h1>`;

    htmlContent += `
    <div class="section">
        <p><strong>${majeur.civility || ""} ${majeur.last_name || ""} ${
        majeur.first_name || ""
    }</strong></p>
        <p>Né(e) le : ${
            majeur.birth_date
                ? moment(majeur.birth_date).format("DD/MM/YYYY")
                : "Non communiquée."
        }</p>
                <p><strong>Lieu de naissance:</strong> ${
                    majeur.birth_location || ""
                }</p>
    </div>
    `;

    htmlContent += `<h2 style="color: #2196F3; margin-top: 20px;">Informations</h2>`;
    htmlContent += `
    <div>
        <p><strong>Numéro sécurité sociale:</strong> ${majeur.n_secu || ""}</p>
        <p><strong>Statut familial:</strong> ${majeur.family_status || ""}</p>
        <p><strong>Nationalité:</strong> ${majeur.nationality || ""}</p>
        <p><strong>Animaux:</strong> ${majeur.animaux || ""}</p>
        <p><strong>Seul:</strong> ${majeur.seul || ""}</p>
    </div>`;

    if (majeur.mesure) {
        htmlContent += generateMeasureFieldsHTML(fields, majeur.mesure);
    }

    htmlContent += generateSectionHTML(
        "Adresses",
        majeur.addresses,
        ids,
        (address: any) => `
        <p><strong>${address.label || "Adresse"}:</strong> ${
            address.address
        }, ${address.city} ${address.zip_code}</p>
        ${
            address.date
                ? `<p><strong>Date d'entrée:</strong> ${moment(
                      address.date
                  ).format("DD/MM/YYYY")} </p>`
                : ""
        }
`
    );
    htmlContent += generateSectionHTML(
        "Téléphones",
        majeur.phones,
        ids,
        (phone: any) =>
            `<strong>${phone.label || "Téléphone"}:</strong> ${phone.phone}`
    );
    htmlContent += generateSectionHTML(
        "Emails",
        majeur.emails,
        ids,
        (email: any) =>
            `<strong>${email.label || "Email"}:</strong> ${email.email}`
    );
    htmlContent += generateSectionHTML(
        "Contrats",
        majeur.contracts,
        ids,
        (contract: any) => `
        <p><strong>${contract.nature}:</strong> ${contract.numero} ${
            contract.contact.organisme || ""
        }</p>
        ${
            contract.end_date
                ? `<p><strong>- Date d'échéance:</strong> ${returnDateFormated(
                      contract.end_date
                  )}</p>`
                : ""
        }
        ${
            contract.contact.phones
                ? generatePhonesHTML(contract.contact.phones)
                : ""
        }
        ${
            contract.notes
                ? `<p><strong>Notes:</strong> ${contract.notes}</p>`
                : ""
        }
    `
    );
    htmlContent += generateSectionHTML(
        "Droits",
        majeur.socials,
        ids,
        (right: any) => `
        <p><strong>${right.nature}:</strong> ${right.numero} ${
            right.contact.organisme || ""
        }</p>
        ${
            right.end_date
                ? `<p><strong>- Date d'échéance:</strong> ${returnDateFormated(
                      right.end_date
                  )}</p>`
                : ""
        }
        ${right.contact.phones ? generatePhonesHTML(right.contact.phones) : ""}
        ${right.notes ? `<p><strong>Notes:</strong> ${right.notes}</p>` : ""}
    `
    );

    if (majeur.contacts) {
        htmlContent += generateContactsFieldsHTML(fields, majeur.contacts);
    }

    htmlContent += generateSectionHTML(
        "Bien mobiliers",
        majeur.mobiliers,
        ids,
        (mobilier: any) =>
            `${mobilier.label}: ${
                mobilier.estimation ? `${mobilier.estimation} euros` : ""
            } ${mobilier.notes || ""}`
    );
    htmlContent += generateSectionHTML(
        "Bien immobiliers",
        majeur.immobiliers,
        ids,
        (immobilier: any) =>
            `${immobilier.label}: ${
                immobilier.estimation ? `${immobilier.estimation} euros` : ""
            } ${immobilier.address || ""} ${immobilier.city || ""} ${
                immobilier.zip_code || ""
            } ${immobilier.notes || ""}`
    );
    htmlContent += generateSectionHTML(
        "Comptes",
        majeur.accounts,
        ids,
        (account: any) =>
            `<strong>${account.type}:</strong> ${account.number || ""} ${
                account.bank_label || ""
            }`
    );

    if (majeur.notes) {
        htmlContent += `
        <h2 style="color: #2196F3; margin-top: 20px;">Notes</h2>
        <div class="section">
            <p>${majeur.notes}</p>
        </div>`;
    }

    htmlContent += `
        </body>
    </html>`;

    return htmlContent;
}

function generateMeasureFieldsHTML(fields: any, measure: any): string {
    let htmlContent =
        '<h2 style="color: #2196F3; margin-top: 20px;">Mesure</h2><div class="section">';
    let hasMeasureField = Object.entries(fields).find(
        ([key, label]) => label === "mesure"
    );
    if (hasMeasureField) {
        htmlContent += `
            <p><strong>Numéro rg:</strong> ${measure.n_rg || ""}</p>
            <p><strong>Date initiale de jugement:</strong> ${
                moment(measure.date_initial_judgment).format("DD/MM/YYYY") || ""
            }</p>
            <p><strong>Date de décision de la mesure:</strong> ${
                moment(measure.date_decision_mesure).format("DD/MM/YYYY") || ""
            }</p>
            <p><strong>Date de révision:</strong> ${
                moment(measure.date_revision).format("DD/MM/YYYY") || ""
            }</p>
            <p><strong>Lieu de vie:</strong> ${
                measure.currentProtectionLocation || ""
            }</p>
            <p><strong>Protection:</strong> ${
                measure.currentProtection || ""
            }</p>
            <p><strong>Nature:</strong> ${
                measure.currentProtectionNature || ""
            }</p>
        `;
    }

    htmlContent += "</div>";
    htmlContent += '<div class="section"></div>';
    return htmlContent;
}

function generateContactsFieldsHTML(fields: any, contacts: any): string {
    let htmlContent =
        '<h2 style="color: #2196F3; margin-top: 20px;">Contact</h2><div class="section">';
    let hasContactsFields = Object.entries(fields).find(
        ([key, label]) => label === "contacts"
    );

    if (hasContactsFields) {
        forEach(contacts, (contact) => {
            htmlContent += `
                <p><strong>${contact.profession || "Contact"}:</strong> ${
                contact.civility || ""
            } ${contact.last_name || ""} ${contact.first_name || ""}</p>
                <p><strong>- Organisme:</strong> ${contact.organisme || ""}</p>
                ${contact.phones ? generatePhonesHTML(contact.phones) : ""}
                ${contact.emails ? generateEmailsHTML(contact.emails) : ""}
            `;
        });
    }

    htmlContent += "</div>";
    return htmlContent;
}

function generatePhonesHTML(phones: any): string {
    let phonesHTML = "";
    for (const phone of phones) {
        phonesHTML += `<p>- <strong>${
            phone.label ? phone.label + ": " : "Téléphone: "
        }</strong>${phone.phone}</p>`;
    }
    return phonesHTML;
}

function generateEmailsHTML(emails: any): string {
    let emailsHTML = "";
    for (const email of emails) {
        emailsHTML += `<p>- <strong>${
            email.label ? email.label + ": " : "Email: "
        }</strong>${email.email}</p>`;
    }
    return emailsHTML;
}

function generateSectionHTML(
    title: any,
    items: any,
    ids: any,
    itemFormatter: any
): string {
    if (!Array.isArray(items) || items.length === 0) return "";

    let sectionContent = `<h2 style="color: #2196F3; margin-top: 20px;">${title}</h2><div class="section">`;
    items.forEach((item: any) => {
        if (ids[item._id]) {
            sectionContent += `<div class="section"> ${itemFormatter(
                item
            )}</div>`;
        }
    });
    sectionContent += "</div>";
    return sectionContent;
}
