import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CountUp from "react-countup";
import Marquee from "react-fast-marquee";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    capitalizeFirst,
    momentUpdateLocale,
    stylesChartProtection,
} from "../../../constants/Utils";
import IToDo from "../../../interfaces/forms/IToDo";
import IAlert from "../../../interfaces/IAlert";
import IFintectureBank from "../../../interfaces/IFintectureBank";
import INews from "../../../interfaces/INews";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import ErrorMessage from "../../layout/ErrorMessage";
import ListTodos from "../../layout/forms/ListTodos";
import CreateEventModal from "./CreateEventModal";
import ModalEvent from "./ModalEvent";

const Home: React.FC = () => {
    const localizer = momentLocalizer(moment);

    const history = useHistory();
    const { addToast } = useToasts();
    const [majeurs, setMajeurs] = useState([]);

    const [fintectureBanksExpire, setFBExpire] =
        useState<Array<IFintectureBank>>(null);
    const [textInputTodo, setTextInputTodo] = useState<HTMLInputElement>(null);
    const [news, setNews] = useState<Array<INews>>(null);
    const [nbTodos, setNbTodos] = useState<number>(0);
    const [todos, setTodos] = useState<Array<IToDo>>(null);
    const [nbMajeurs, setNbMajeurs] = useState<number>(0);
    const [nbAlarms, setNbAlarms] = useState<number>(0);
    const [alarms, setAlarms] = useState<any>(null);
    const [user, setUser] = useState<IUser>(null);

    const [dataProtectionGraph, setDataProtectionGraph] = useState<any>();
    const [unclassifiedDocuments, setUnclassifiedDocuments] =
        useState<Array<any>>(null);

    const [calendarAlerts, setCalendarAlerts] = useState<any>(null);
    const [calendarDayAlerts, setCalendarDayAlerts] = useState<any>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    // to check the free usage time remaining of the application
    let numberOfDays =
        user && user.abonnement
            ? moment
                  .duration(
                      moment(user.abonnement.end_free_trial_at)
                          .add(4, "hours")
                          .diff(moment())
                  )
                  .days() + 1
            : 0;

    let days = "jours";
    if (numberOfDays === 1) {
        days = "jour";
    }

    momentUpdateLocale();

    const events = [
        {
            id: 1,
            title: (
                <a href="https://zoom.us/j/94797343699" target="_blank">
                    [Lien direct] - Atelier - Fonctionnalité ressource OCMI
                </a>
            ),
            date: "25/04/2025 à 14h",
        },
        {
            id: 2,
            title: (
                <a href="https://zoom.us/j/93741682505" target="_blank">
                    [Lien direct] - Club utilisateur - Discussions
                    fonctionnalités
                </a>
            ),
            date: "27/06/2025 à 14h",
        },
    ];

    // here we get all datas to display in the dashboard
    useEffect(() => {
        if (!user) {
            request({ addToast, url: "/api/user" })
                .then(({ data }) => {
                    setUser(data);
                })
                .catch();
        }
        if (user && !news && !calendarAlerts) {
            request({ addToast, url: `/api/dashboard/stats/${user._id}` }).then(
                ({ data }) => {
                    setNews(data.news);
                    setNbTodos(data.nbTodos);
                    actualiseToDos();
                    setNbMajeurs(data.nbMajeurs);
                    setMajeurs(data.majeurs);
                    setNbAlarms(data.nbAlarms);
                    setDataProtectionGraph(data.chartStats);
                    drawChart(
                        document.getElementById("protection_graph"),
                        data.chartStats
                    );
                    //}
                    // Get fintecture banks will be expired soon
                    request({
                        addToast,
                        url: `/api/fintecture/next-expirations/${user._id}`,
                    })
                        .then(({ data }) => {
                            setFBExpire(data.banks);
                        })
                        .catch();
                }
            );
            // Get all alerts
            refreshAlarms();

            // Get alert date_final < 7 days
            request({ addToast, url: "api/alarms" })
                .then(({ data }) => {
                    setAlarms(data);
                })
                .catch();
        }
        localStorage.setItem("location", "/dashboard");
    }, [news, user]);

    useEffect(() => {
        if (!unclassifiedDocuments) {
            request({ addToast, url: `api/dashboard/unclassified-documents` })
                .then(({ data }) => {
                    setUnclassifiedDocuments(data.unclassifiedDocuments);
                })
                .catch();
        }
    }, [unclassifiedDocuments]);

    const refreshAlarms = () => {
        request({ addToast, url: `/api/alarms/${user._id}/all` })
            .then(({ data }) => {
                setCalendarAlerts(formatAlarmsForCalendar(data.alarms));
            })
            .catch();
    };
    // Reformat alert to appear in the calendar
    const formatAlarmsForCalendar = (alarms: any) => {
        let calendarAlerts: any = [];
        alarms.forEach(function (alarm: IAlert, index: number) {
            let tempFirstName = alarm.protected_adult
                ? `${alarm.protected_adult.first_name}`
                : null;

            let tempLastName = alarm.protected_adult
                ? `${alarm.protected_adult.last_name}`
                : null;

            calendarAlerts.push({
                id: index,
                protected_adult: alarm.protected_adult ? true : false,
                first_name: tempFirstName,
                last_name: tempLastName,
                event: alarm.label,
                alarm_id: alarm._id,
                start: moment(alarm.date_alarm),
                end: moment(alarm.date_alarm),
                resourceId: index + 1,
            });
        });

        return calendarAlerts;
    };

    // add a ToDo in the user list
    const onSubmitTodo = (text: HTMLInputElement) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (text.value != null) {
            request({
                addToast,
                url: `api/toDos/create/${user._id}`,
                method: "post",
                data: { label: capitalizeFirst(text.value.trim()) },
            })
                .then((data) => {
                    addToast(`La tâche a bien été ajoutée`, {
                        appearance: "success",
                    });
                    actualiseToDos();
                    text.value = "";
                })
                .catch();
        }
    };

    // actualise ToDos on the dashboard
    const actualiseToDos = () => {
        request({ addToast, url: `/api/toDos/${user._id}` })
            .then(({ data }) => {
                setTodos(data.toDos);
                setNbTodos(data.toDos.length);
            })
            .catch();
    };

    // draw the statistic chart
    const drawChart = (chartDiv: HTMLElement, data?: any) => {
        /*dataProtectionGraph &&*/
        chartDiv &&
            Highcharts.chart(chartDiv, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                },
                title: {
                    text: "Protection en cours",
                    align: "center",
                    verticalAlign: "bottom",
                    y: 15,
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -40,
                            style: {
                                fontWeight: "bold",
                                color: "white",
                                textShadow: "0px 1px 2px black",
                            },
                        },
                        startAngle: -180,
                        endAngle: 180,
                        center: ["50%", "50%"],
                    },
                },
                series: [
                    {
                        type: "pie",
                        innerSize: "50%",
                        name: "Nombre",
                        data: data ? data : dataProtectionGraph,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            });
    };

    // redirect on the alert page
    const redirectOnAlert = (event: any) => {
        history.push(`/alertes/${event.alarm_id}`);
    };

    // Custom event for Big Calendar
    const EventComponent = ({ event }: any) => {
        const name = event.last_name
            ? " : " + event.last_name + " " + event.first_name
            : "";
        return (
            <div
                style={{
                    padding: "2px 5px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
                title={event.event && `${event.event} ${name}`}
            >
                {event.event && `${event.event} ${name}`}
            </div>
        );
    };

    return (
        <>
            <div className="dashboard_home">
                {/* Abonnement */}
                {user &&
                    user.abonnement &&
                    !user.abonnement.activated &&
                    numberOfDays > 0 && (
                        <>
                            <ErrorMessage
                                text={`Votre période d'essai se termine dans ${numberOfDays} ${days}`}
                            />
                        </>
                    )}
                {user &&
                    user.abonnement &&
                    !user.abonnement.activated &&
                    numberOfDays < 0 && (
                        <>
                            <ErrorMessage
                                text={`Votre période d'essai est terminée. Veuillez activer votre abonnement !`}
                            />
                        </>
                    )}

                {/* News section */}
                <div className="news-scrolling">
                    <Marquee speed={60} gradient={false}>
                        {news &&
                            news.length > 0 &&
                            news.map((article, index) => (
                                <Link
                                    key={index}
                                    to={{
                                        pathname: `/news/${article._id}`,
                                        state: news,
                                    }}
                                >
                                    <b>
                                        {moment(article.updated_at).format("L")}
                                    </b>{" "}
                                    : {article.title} &bull;
                                </Link>
                            ))}
                    </Marquee>
                </div>
                <div className="flex justify-end mr-4 mt-2">
                    {user && user.profile.isEmailServiceActive && (
                        <div
                            style={{
                                color: "blue",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <button className="m-1 bg-transparent font-semibold hover:text-white py-2 px-4 border border-blue-500 rounded">
                                <a
                                    target="_blank"
                                    href={`${process.env.WEBMAIL_URL}/?_user=${
                                        user.profile.isEmailServiceActive
                                    }${
                                        user.profile.powermail_extension ||
                                        "@accelgerancia-tutelle.fr"
                                    }`}
                                >
                                    {" "}
                                    Acceder à ma boite powermail
                                </a>
                            </button>
                        </div>
                    )}
                </div>

                <div className="page_content">
                    {/* Displaying the chart */}
                    {user &&
                        user.type !== "_FAMILLIALE_" &&
                        user.roles[0] !== "FAMILLE" && (
                            <div className="row layout-xs-column">
                                <div
                                    id="protection_graph"
                                    style={stylesChartProtection}
                                />
                                <div className="w-full circle_container layout-space-around-center row layout-xs-column">
                                    <div className="layout-center-center flex-1 m-4">
                                        <Link to={"/list_majeurs"}>
                                            <div className="layout-start-center column circle first_circle blue">
                                                <i className="material-icons">
                                                    people
                                                </i>
                                                <div className="number layout-center-center row">
                                                    <CountUp
                                                        start={0}
                                                        end={nbMajeurs}
                                                        duration={2.75}
                                                        useEasing
                                                        separator=" "
                                                        decimal=","
                                                        prefix=""
                                                        suffix=""
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="layout-center-center flex-1 m-4">
                                        <Link to="/alertes">
                                            <div className="layout-start-center column circle blue">
                                                <i className="material-icons">
                                                    notifications
                                                </i>
                                                <div className="number layout-center-center row">
                                                    <CountUp
                                                        start={0}
                                                        end={nbAlarms}
                                                        duration={2.75}
                                                        useEasing
                                                        separator=" "
                                                        decimal=","
                                                        prefix=""
                                                        suffix=""
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-4 p-6 bg-white rounded-xl shadow-md">
                                        <h2 className="text-2xl font-semibold mb-4">
                                            Prochains Événements
                                        </h2>
                                        <ul>
                                            {events.map((event) => (
                                                <li
                                                    key={event.id}
                                                    className="border-b last:border-b-0 border-gray-200 py-2"
                                                >
                                                    <span className="text-gray-600">
                                                        {event.date}
                                                    </span>{" "}
                                                    -{" "}
                                                    <span className="font-medium">
                                                        {event.title}
                                                    </span>{" "}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    {user &&
                        unclassifiedDocuments &&
                        unclassifiedDocuments.length > 0 && (
                            <div
                                className={"alert alert-danger pointer"}
                                style={{ marginTop: "20px" }}
                            >
                                <Link
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#721c24",
                                    }}
                                    to="/settings/cloud"
                                >
                                    <span>
                                        {" "}
                                        GED : Documents à classer - Vous avez{" "}
                                        <b>
                                            {unclassifiedDocuments.length}
                                        </b>{" "}
                                        documents en attente de classements.
                                    </span>
                                    <div style={{ marginTop: "10px" }}>
                                        {unclassifiedDocuments.map(
                                            (doc, index) => (
                                                <span
                                                    style={{
                                                        marginLeft: "35px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    {doc.name}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </Link>
                            </div>
                        )}

                    {/* Fintecture banks expiration */}
                    {fintectureBanksExpire &&
                        fintectureBanksExpire.length > 0 && (
                            <div
                                className="mt-3 alert alert-danger pointer"
                                onClick={() => {
                                    history.push(`/settings/abo`);
                                }}
                            >
                                Attention :{" "}
                                <b>{fintectureBanksExpire.length}</b>{" "}
                                {fintectureBanksExpire.length === 1
                                    ? "banque a expiré où est sur le point d'expirer."
                                    : "banques ont expiré ou sont sur le point d'expirer."}
                                <ul>
                                    {fintectureBanksExpire.map(
                                        (bank, index) => (
                                            <li key={index}>
                                                {bank.name} - Connexion ouverte
                                                le {bank.expires.date} (
                                                {bank.expires.in > 1
                                                    ? `Expire dans ${bank.expires.in} jours`
                                                    : "Expirée"}
                                                )
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                </div>

                <div className="flex-2 homebox1 layout-xs-column">
                    {calendarAlerts && (
                        <div
                            className="relative column-item-flex-1 layout-center-center margin-top"
                            style={{ width: "100% !important" }}
                        >
                            <button
                                onClick={() => setIsCreating(true)}
                                className="absolute top-0 right-20 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                            >
                                + Ajouter
                            </button>
                            <Calendar
                                localizer={localizer}
                                events={calendarAlerts}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: "100%", width: "80%" }}
                                views={["month"]}
                                onSelectEvent={(e: any) => redirectOnAlert(e)}
                                components={{ event: EventComponent }}
                                messages={{
                                    next: "Suivant",
                                    previous: "Précédent",
                                    today: "Aujourd'hui",
                                    showMore: (total) =>
                                        `+ ${total} évènements`, // Exemple plus concis
                                }}
                                onShowMore={(events) => {
                                    setCalendarDayAlerts(events);
                                    setIsOpen(true);
                                }}
                            />
                        </div>
                    )}
                    <div className="column-item-flex-1 layout-center margin-top-xs">
                        <h2 className="margin-bottom text-align-center">
                            Tâches
                        </h2>
                        {/* ToDos section */}
                        {user && (
                            <div className="full-height">
                                <form
                                    onSubmit={() => onSubmitTodo(textInputTodo)}
                                    className="full-width todo_form layout-center-center"
                                >
                                    <input
                                        ref={(input) => setTextInputTodo(input)}
                                        type="text"
                                        placeholder="Que devez vous faire ?"
                                    />
                                </form>
                                <ListTodos
                                    onChange={() => actualiseToDos()}
                                    toDos={todos}
                                    user_id={user._id}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isCreating && (
                <CreateEventModal
                    isOpen={isCreating}
                    majeurs={majeurs}
                    setIsOpen={setIsCreating}
                    refreshAlarms={refreshAlarms}
                />
            )}
            {isOpen && (
                <ModalEvent
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    events={calendarDayAlerts}
                />
            )}
        </>
    );
};
export default Home;
