import { request } from "../utils/axios-utils";

export const createAlarm = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: "/api/alarms/create",
        method: "post",
        data: payload,
    });
};

export const createEvent = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: "/api/event",
        method: "post",
        data: payload,
    });
};

export const downloadICS = (addToast: any, _id: string) => {
    return request({
        addToast,
        url: "/api/alarm/export/" + _id,
        method: "get",
        responseType: "blob", // Ajout pour récupérer un fichier
    });
};
