import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { getDepots } from "../../../../_api/Depot";
import { getHistories } from "../../../../_api/History";
import { getControls } from "../../../../_api/Mandat";
import { getMessages } from "../../../../_api/Message";

const DiscussionsTable = (props) => {
    const { histories } = props;
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(histories.length / itemsPerPage);

    const handlePrev = () => setPage((prev) => Math.max(prev - 1, 1));
    const handleNext = () => setPage((prev) => Math.min(prev + 1, totalPages));

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const optionsTime = { hour: "2-digit", minute: "2-digit" };
        const optionsDate = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        };

        const time = date.toLocaleTimeString("fr-FR", optionsTime);
        const fullDate = date.toLocaleDateString("fr-FR", optionsDate);

        return `${fullDate} à ${time}`;
    };

    return (
        <div className="m-4 p-6 bg-white rounded-xl shadow-md">
            <table className="w-full border-collapse border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Horaire</th>
                        <th className="border border-gray-300 p-2">Auteur</th>
                        <th className="border border-gray-300 p-2">Message</th>
                        <th className="border border-gray-300 p-2">Protégé</th>
                    </tr>
                </thead>
                <tbody>
                    {histories
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((discussion) => (
                            <tr
                                key={discussion.id}
                                className="text-center border border-gray-300"
                            >
                                <td className="p-2 min-w-[180px]">
                                    {formatDate(discussion.created_at)}
                                </td>
                                <td className="p-2 min-w-[200px]">
                                    {discussion.user.profile.infos.last_name +
                                        " " +
                                        discussion.user.profile.infos
                                            .first_name}
                                </td>

                                <td className="p-2" title={discussion.text}>
                                    {" "}
                                    {discussion.text.length > 100
                                        ? discussion.text.slice(0, 100) + "..."
                                        : discussion.text}
                                </td>
                                <td className="p-2">
                                    {discussion.control.second_name}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className="flex justify-between mt-4">
                <button
                    onClick={handlePrev}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    disabled={page === 1}
                >
                    Précédent
                </button>
                <span>
                    Page {page} / {totalPages}
                </span>
                <button
                    onClick={handleNext}
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    disabled={page === totalPages}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export const AccueilCRG = () => {
    const [mandats, setMandats] = useState([]);
    const [controls, setControls] = useState([]);
    const [histories, setHistories] = useState([]);
    const [messages, setMessages] = useState([]);

    const [activeTab, setActiveTab] = useState("discussions");

    const { addToast } = useToasts();

    useEffect(() => {
        getControls(addToast).then((resp) => {
            setMandats(resp.data);
        });

        getDepots(addToast).then((resp) => {
            setControls(resp.data);
        });

        getHistories(addToast).then((resp) => {
            setHistories(resp.data);
        });

        getMessages(addToast).then((resp) => {
            setMessages(resp.data);
        });
    }, []);

    const events = [
        {
            id: 1,
            title: "Aucun évènement n'est prévu pour le moment",
            date: "27/02/2025",
        },
    ];

    const cardKPI = [
        {
            label: "# mandats en cours",
            value: mandats.filter((mandat) => mandat.isArchived === false)
                .length,
        },
        {
            label: "# contrôle en cours",
            value: controls.filter((control) => control.status !== "SUCCESS")
                .length,
        },
        {
            label: "En attente de dépot",
            value: controls.filter(
                (control) => control.status === "WAITING_DEPOT"
            ).length,
        },
        {
            label: "En attente de validation",
            value: controls.filter(
                (control) => control.status === "WAITING_VERIF"
            ).length,
        },
    ];

    return (
        <>
            <div className="bg-gray-50 dark:bg-gray-900">
                <div className="container max-w-6xl px-5 mx-auto mt-2">
                    <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                        {cardKPI.map((kpi) => {
                            return (
                                <div className="p-5 bg-white rounded shadow-sm">
                                    <div className="text-base text-gray-400 ">
                                        {kpi.label}
                                    </div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-2xl font-bold text-gray-900 ">
                                            {kpi.value}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="flex justify-between mt-4 p-4 m-4">
                    <div className="w-1/3 m-4 p-6 bg-white rounded-xl shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">
                            Prochains Événements
                        </h2>
                        <ul>
                            {events.map((event) => (
                                <li
                                    key={event.id}
                                    className="border-b last:border-b-0 border-gray-200 py-2"
                                >
                                    <span className="font-medium">
                                        {event.title}
                                    </span>{" "}
                                    -{" "}
                                    <span className="text-gray-600">
                                        {event.date}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-2/3 p-6 bg-white rounded-xl shadow-md">
                        <div className="flex border-b">
                            <button
                                className={`p-3 w-1/2 text-center ${
                                    activeTab === "discussions"
                                        ? "border-b-2 border-blue-500"
                                        : ""
                                }`}
                                onClick={() => setActiveTab("discussions")}
                            >
                                Discussions sur les mandats et contrôles
                            </button>
                            <button
                                className={`p-3 w-1/2 text-center ${
                                    activeTab === "events"
                                        ? "border-b-2 border-blue-500"
                                        : ""
                                }`}
                                onClick={() => setActiveTab("events")}
                            >
                                Événements
                            </button>
                        </div>
                        <div className="mt-4">
                            {activeTab === "discussions" ? (
                                <DiscussionsTable histories={messages} />
                            ) : (
                                <DiscussionsTable histories={histories} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
