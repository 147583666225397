import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { createMessage } from "../../../../_api/Message";
import { Button } from "../../../../_shared/Button";

const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set to true for 12-hour format
};

export const Comments = (props: {
    messages: any;
    type: string;
    control_id?: string;
    depot_id?: string;
    refreshEntity: Function;
}) => {
    const { addToast } = useToasts();

    const { messages, type, control_id, depot_id, refreshEntity } = props;

    const [textArea, setTextArea] = useState("");
    return (
        <section className=" dark:bg-gray-900  antialiased">
            <div className="px-4">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg lg:text-xl font-bold text-gray-900 dark:text-white">
                        Discussions ({messages.length})
                    </h2>
                    <Button
                        type={"empty"}
                        name={"Publier un commentaire"}
                        action={() => {
                            let _id =
                                type === "CONTROL" ? control_id : depot_id;
                            const payload = {
                                control_id,
                                depot_id,
                                text: textArea,
                            };

                            createMessage(addToast, payload, type, _id).then(
                                (resp) => {
                                    addToast(resp.data.message, {
                                        appearance: "success",
                                    });
                                    setTextArea("");
                                    refreshEntity();
                                }
                            );
                        }}
                    />
                </div>

                <form className="border">
                    <div className="py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        <textarea
                            id="comment"
                            rows={4}
                            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                            placeholder="Ecrire un commentaire..."
                            value={textArea}
                            onChange={(e) => setTextArea(e.target.value)}
                            required
                        ></textarea>
                    </div>
                </form>
                <div className="max-h-96 overflow-y-scroll">
                    {messages.map((message: any) => {
                        return (
                            <article className="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
                                <footer className="flex justify-between items-center mb-2">
                                    <div className="flex items-center">
                                        <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                                            <svg
                                                className="mr-2 w-6 h-6 text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12 2a6 6 0 100 12 6 6 0 000-12zM2 20a10 10 0 0120 0H2z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            {
                                                message.user.profile.infos
                                                    .last_name
                                            }
                                        </p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">
                                            <time title="February 8th, 2022">
                                                {new Date(
                                                    message.created_at
                                                ).toLocaleString(
                                                    "fr-FR",
                                                    options
                                                )}
                                            </time>
                                        </p>
                                    </div>
                                </footer>
                                <p className="text-gray-500 dark:text-gray-400">
                                    {message.text}
                                </p>
                            </article>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
