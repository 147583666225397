import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { loadInvoices } from "../../../../_api/InvoicingMajeur";
import Loader from "../../../layout/Loader";
import { useAuth } from "../../Login/auth.context";
import SubscriptionForm from "../SubscriptionForm";

export const Invoice = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const { addToast } = useToasts();

    useEffect(() => {
        loadInvoices(addToast).then((resp) => {
            setInvoices(resp.data);
            setIsLoading(false);
        });
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
    const { currentUser } = useAuth();

    const [companyName, setCompanyName] = useState(
        currentUser?.crg_infos ? currentUser.crg_infos.companyName : ""
    );
    return (
        <>
            <Elements stripe={stripePromise}>
                <SubscriptionForm
                    companyName={companyName}
                    currentUser={currentUser}
                />
            </Elements>
            <h4 className="m-4">Liste des factures</h4>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {isLoading && (
                    <div className="flex items-center justify-center h-64">
                        <Loader />
                    </div>
                )}
                {!isLoading && (
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                {currentUser.roles[0] === "judge" && (
                                    <th scope="col" className="px-6 py-3">
                                        Contrôleur
                                    </th>
                                )}
                                <th scope="col" className="px-6 py-3">
                                    Période
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Montant HT
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Montant TTC
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                    >
                                        {currentUser.roles[0] === "judge" && (
                                            <td className="px-6 py-4">
                                                {item.controler_name}
                                            </td>
                                        )}
                                        <td className="px-6 py-4">
                                            {item.rg_number}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.tribunal_name}
                                        </td>

                                        <td className="px-6 py-4">
                                            <Link
                                                to={"/mandat/" + item._id}
                                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                            >
                                                Voir
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            {invoices.length === 0 && (
                                <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                    <td colSpan={6}>
                                        Il n'y a actuellement aucune facture.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};
