import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { getUsersOfAdmin } from "../../../../_api/User";

export const Users = () => {
    const [users, setUsers] = useState([]);
    const { addToast } = useToasts();

    useEffect(() => {
        getUsersOfAdmin(addToast).then((resp) => {
            setUsers(resp.data);
        });
    }, []);

    console.log(users);
    return (
        <div className=" p-6 bg-white rounded-xl shadow-md mt-10">
            <div className="flex justify-between">
                <h2 className="text-2xl font-semibold mb-4">
                    Liste des utilisateurs
                </h2>
                <button
                    onClick={() => console.log("DO NOTHING")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Ajout à la demande (Contactez-nous)
                </button>
            </div>
            <table className="w-full border-collapse border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Nom</th>
                        <th className="border border-gray-300 p-2">Prénom</th>
                        <th className="border border-gray-300 p-2">Email</th>
                        <th className="border border-gray-300 p-2">
                            Type de compte
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr
                            key={user.id}
                            className="text-center border border-gray-300"
                        >
                            <td className="p-2">
                                {user.profile.infos.last_name}
                            </td>
                            <td className="p-2">
                                {user.profile.infos.first_name}
                            </td>
                            <td className="p-2">{user.emails[0].address}</td>
                            <td className="p-2">Utilisateur</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
