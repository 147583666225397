import { request } from "../utils/axios-utils";

const namespace = "/api/mandat";

export const getSimulateurTarif = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: namespace + "/simulateur",
        method: "post",
        data: payload,
    });
};
