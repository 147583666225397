import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    endOfTodayDate,
    momentUpdateLocale,
    startOfYearDate,
} from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISuivis from "../../../../interfaces/ISuivis";
import { request } from "../../../../utils/axios-utils";
import ItemTwoLinesLink from "../../../layout/ItemTwoLinesLink";
import Loader from "../../../layout/Loader";
import NoItem from "../../../layout/NoItem";
import InputDatePicker from "../../../layout/forms/InputDatePicker";

const SuivisMajeurs: React.FC<{ majeurId: string; majeur: IProtectedAdult }> = (
    props
) => {
    const [startDate, setStartDate] = useState(
        localStorage.getItem("start_date")
            ? new Date(
                  moment(localStorage.getItem("start_date"), "DD/MM/YYYY")
                      .startOf("day")
                      .format()
              )
            : startOfYearDate
    );
    const [endDate, setEndDate] = useState(
        localStorage.getItem("end_date")
            ? new Date(
                  moment(localStorage.getItem("end_date"), "DD/MM/YYYY")
                      .endOf("day")
                      .format()
              )
            : endOfTodayDate
    );
    const [initSuivis, setInitSuivis] = useState<Array<ISuivis> | null>([]);
    const [suivis, setSuivis] = useState<Array<ISuivis> | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [statsMajeur, setStatsMajeur] = useState<any>(null);
    const [colors, setColors] = useState<Array<string>>(null);
    const [chartDiv, setChartDiv] = useState<HTMLElement>(null);

    const { addToast } = useToasts();

    momentUpdateLocale();

    useEffect(() => {
        setIsLoading(true);
        if (!suivis) {
            request({
                addToast,
                url: `/api/majeurs/suivis/${props.majeurId}`,
            })
                .then(({ data }) => {
                    setSuivis(data);
                    setInitSuivis(data);
                    filterSuivis(startDate, endDate, data);
                })
                .catch();
        }

        setIsLoading(false);
    }, [suivis, initSuivis]);

    useEffect(() => {
        if (!statsMajeur && !chartDiv) {
            getStats(startDate, endDate);
        }
    }, []);

    const drawCharts = (
        dataGrouped: any,
        colorsArray: any,
        chartDiv: HTMLElement
    ) => {
        dataGrouped &&
            chartDiv &&
            Highcharts.chart(chartDiv, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    height: 200,
                    width: 200,
                    backgroundColor: "transparent",
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },
                tooltip: {
                    pointFormat:
                        "Temps passé : <b>{point.hours}h {point.minutes}mn</b>",
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                series: [
                    {
                        type: "pie",
                        name: "Time",
                        colors: colorsArray,
                        data: dataGrouped,
                    },
                ],
                credits: {
                    enabled: true,
                    text: "",
                },
            });
    };

    const downloadSuivis = () => {
        if (suivis.length > 0) setIsLoading(true);
        request({
            addToast,
            url: "/api/suivis/download",
            // responseType: "blob",
            method: "post",
            data: {
                majeur_id: props.majeurId,
                suivis: suivis,
            },
        })
            .then(({ data }) => {
                document.location.href = data;

                // const url = window.URL.createObjectURL(data);
                // const link = document.createElement("a");
                // link.href = url;
                // link.setAttribute("download", "suivi.docx"); // Nom du fichier
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);
                // setIsLoading(false);
            })
            .catch();
    };

    const getStats = (startDate: Date, endDate: Date) => {
        request({
            addToast,
            url: `/api/statistics/majeur/suivis`,
            method: "post",
            data: {
                majeur_id: props.majeurId,
                start_date: startDate,
                end_date: endDate,
            },
        })
            .then(({ data }) => {
                if (data && data.group_by_tags && data.colors) {
                    setStatsMajeur(data.group_by_tags);
                    setColors(data.colors);
                    drawCharts(
                        data.group_by_tags,
                        data.colors,
                        document.getElementById("container_stats_protege")
                    );
                }
            })
            .catch();
    };

    const filterSuivis = (
        startDate: Date,
        endDate: Date,
        suivis?: Array<ISuivis>
    ) => {
        let trackings = suivis ? suivis : initSuivis;
        let suivisFiltered = trackings.filter(function (suivi) {
            let suivisDate = new Date(
                moment(
                    suivi.date.$date
                        ? parseInt(suivi.date.$date.$numberLong)
                        : suivi.date
                ).format()
            );

            return suivisDate >= startDate && suivisDate <= endDate;
        });

        setSuivis(suivisFiltered);
    };

    return (
        <div className="list_suivis_majeurs">
            <div className="subBar_actions layout-space-between-center row">
                <span className="subBar_actions_title">Les suivis</span>
                <div className="layout-center-center row subBar_actions_item">
                    <span style={{ color: "white", marginRight: 5 }}>
                        Du :{" "}
                    </span>
                    <InputDatePicker
                        id="start_date"
                        inputDate={moment(startDate, "DD/MM/YYYY").toDate()}
                        onChange={(d: Date) => {
                            d.setHours(0, 0, 0, 0);
                            setStartDate(d),
                                getStats(d, endDate),
                                filterSuivis(d, endDate);
                            localStorage.setItem(
                                "start_date",
                                d.toLocaleDateString("FR")
                            );
                        }}
                    />
                    <div className="flex005 hide-xs" />
                    <span style={{ color: "white", marginRight: 5 }}>
                        Au :{" "}
                    </span>
                    <InputDatePicker
                        id="end_date"
                        inputDate={moment(endDate, "DD/MM/YYYY").toDate()}
                        onChange={(d: Date) => {
                            d.setHours(23, 59, 59, 999);
                            setEndDate(d),
                                getStats(startDate, d),
                                filterSuivis(startDate, d);
                            localStorage.setItem(
                                "end_date",
                                d.toLocaleDateString("FR")
                            );
                        }}
                    />
                    <div
                        style={{ marginLeft: 10 }}
                        className="hide-xs action-button"
                        onClick={downloadSuivis}
                    >
                        <i className="material-icons">file_download</i>
                    </div>
                    {!props.majeur.archived && (
                        <Link
                            to={`/form_suivi/${props.majeurId}`}
                            className="action-button"
                        >
                            <i className="material-icons">add</i>
                        </Link>
                    )}
                </div>
            </div>
            <div className="page_content column list_suivis_container">
                {isLoading && <Loader />}

                {suivis && suivis.length === 0 && !isLoading && (
                    <NoItem text="Il n'y a pas de suivi pour cette date." />
                )}
                {suivis &&
                    suivis.length > 0 &&
                    !isLoading &&
                    suivis.map((s, index) => (
                        <ItemTwoLinesLink
                            key={index}
                            link={`/single_suivi/${s._id}`}
                            title={
                                moment(
                                    s.date.$date
                                        ? parseInt(s.date.$date.$numberLong)
                                        : s.date
                                ).format("L") +
                                " | " +
                                s.hours +
                                " h " +
                                s.minutes +
                                " mn"
                            }
                            content={s.label}
                            tags={s.tags}
                        />
                    ))}
                {suivis && suivis.length > 0 && (
                    <div
                        id="container_stats_protege"
                        className="container_stats statistic_chart"
                    />
                )}
            </div>
        </div>
    );
};
export default SuivisMajeurs;
