import React, { useState } from "react";
import { useAuth } from "../Login/auth.context";

const categories = [
    {
        id: 0,
        title: "Club utilisateur",
        roles: ["super-admin"],
    },
    {
        id: 1,
        title: "Présentation plateforme CRG",
        roles: [
            "judge",
            "verificateur_crg",
            "verificateur_crg_user",
            "depot_crg",
        ],
    },
];

const videos = [
    {
        id: 1,
        title: "Présentation - Espace magistrat",
        category: 1,
        url: "https://www.youtube.com/embed/xzbZLzLvl7A?si=09_jRHZkHQsKsjZ_",
        thumbnail: "https://img.youtube.com/vi/xzbZLzLvl7A/hqdefault.jpg",
        roles: ["judge", "verificateur_crg", "verificateur_crg_user"],
    },
    {
        id: 2,
        title: "Présentation - Espace controlé",
        category: 1,
        url: "https://www.youtube.com/embed/RX8r0je4zYM?si=tyRdO8FEjIO_Fr5v",
        thumbnail: "https://img.youtube.com/vi/RX8r0je4zYM/hqdefault.jpg",
        roles: [
            "judge",
            "verificateur_crg",
            "verificateur_crg_user",
            "depot_crg",
        ],
    },
    {
        id: 3,
        title: "Club utilisateur #2",
        category: 0,
        url: "https://www.youtube.com/embed/-eKyJ68SFlU?si=rD8f47oEijYhdnAz",
        thumbnail: "https://img.youtube.com/vi/-eKyJ68SFlU/hqdefault.jpg",
        roles: ["super-admin"],
    },
];

export const Bibliotheque = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const { currentUser } = useAuth();

    const filteredVideos = videos.filter((video) =>
        currentUser.roles.some((role) => video.roles.includes(role))
    );
    const filteredCategories = categories.filter((category) =>
        currentUser.roles.some((role) => category.roles.includes(role))
    );

    return (
        <>
            {filteredCategories.map((category) => {
                return (
                    <>
                        <h4 className="m-4">{category.title}</h4>
                        <div className="grid grid-cols-5 gap-4 p-4">
                            {filteredVideos.map((video) => (
                                <div
                                    key={video.id}
                                    className="cursor-pointer text-center"
                                    onClick={() => setSelectedVideo(video)}
                                >
                                    <img
                                        src={video.thumbnail}
                                        alt={video.title}
                                        className="w-full rounded-lg shadow-md hover:opacity-80 transition-opacity"
                                    />
                                    <p className="mt-2">{video.title}</p>
                                </div>
                            ))}{" "}
                        </div>
                    </>
                );
            })}

            {selectedVideo && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={() => setSelectedVideo(null)}
                >
                    <div
                        className="bg-white p-4 rounded-lg w-4/6"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <iframe
                            width="1080"
                            height="630"
                            src={selectedVideo.url}
                            title={selectedVideo.title}
                            frameBorder="0"
                            allowFullScreen
                            className="w-full"
                        ></iframe>
                    </div>
                </div>
            )}
        </>
    );
};
