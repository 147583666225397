import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import SubscriptionForm from "../../pages/CRGSubmission/SubscriptionForm";
import { useAuth } from "../../pages/Login/auth.context";

const ModalFactory: React.FC<{
    activated: Boolean;
    title: string;
    stripeReady: boolean;
    subscribeWithSepa: Function;
    updateSepa: Function;
}> = (props) => {
    const { currentUser } = useAuth();

    const stripePromise = loadStripe(
        currentUser.crg_infos
            ? process.env.REACT_APP_STRIPE_PUBLIC_CRG
            : process.env.REACT_APP_STRIPE_PUBLIC
    );
    return (
        <Elements stripe={stripePromise}>
            <SubscriptionForm
                companyName={currentUser.emails[0]["address"]}
                currentUser={currentUser}
            />
        </Elements>
    );
};

export default ModalFactory;
