import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { createSuivi, editSuivi } from "../../../../_api/Suivi";
import {
    capitalizeFirst,
    hoursOptions,
    minutesOptions,
    modules,
} from "../../../../constants/Utils";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import ISuivis from "../../../../interfaces/ISuivis";
import { request } from "../../../../utils/axios-utils";
import ArrowBack from "../../../layout/ArrowBack";
import InputComponent from "../../../layout/forms/InputComponent";
import InputDatePicker from "../../../layout/forms/InputDatePicker";
import Select from "../../../layout/forms/Select";
import SpeechInput from "../../../layout/forms/SpeechInput";

const CreateUpdateSuivi: React.FC = (props) => {
    const fields: object = { text: "label", value: "_id" };
    const { addToast } = useToasts();

    const [isNew, setIsNew] = useState(false);

    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [suivi, setSuivi] = useState<ISuivis | null>(null);

    const { majeurId }: { majeurId?: string } = useParams();
    const { suivisId }: { suivisId?: string } = useParams();

    /* Form */
    const [suivi_date, setSuiviDate] = useState(new Date());
    const [hour, setHour] = useState();
    const [minute, setMinute] = useState();
    const [bodyValue, setBodyValue] = useState<string>("");

    const [text, setText] = useState("");

    const [km, setKm] = useState<string>("0");
    const [tags, setTags] = useState<Array<string>>([]);
    const [tagsOptions, setTagsOptions] = useState<Array<any>>(null);
    const [object, setObject] = useState("");

    const history = useHistory();

    useEffect(() => {
        if (majeurId && !majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
        }

        if (!tagsOptions) {
            request({ addToast, url: `/api/users/tags` })
                .then(({ data }) => {
                    let tagOptions = data.map((item: any) => ({
                        label: item.label,
                        _id: item._id,
                    }));
                    setTagsOptions(tagOptions);
                })
                .catch();
        }

        // Call this to get your 'Suivi'
        if (suivisId && !suivi) {
            request({ addToast, url: `/api/suivis/${suivisId}` })
                .then(({ data }) => {
                    setSuivi(data);
                    setSuiviDate(
                        new Date(
                            data.date.$date
                                ? parseInt(data.date.$date.$numberLong)
                                : data.date
                        )
                    );
                    setHour(data.hours);
                    setMinute(data.minutes);
                    setText(data.content);
                    setBodyValue(data.content);
                    setKm(data.nb_km);

                    //Set tags & fill the mutliselect automatically
                    let tagsIds: Array<string> = [];
                    data.tags.forEach(function (tag: any) {
                        tagsIds.push(tag._id);
                    });
                    setTags(tagsIds);

                    setObject(data.label);
                })
                .catch();
        }

        if (!suivisId) {
            setIsNew(true);
        }
    }, []);

    let title = "";
    if (majeur && window.screen.width > 600) {
        title = `${majeur.first_name} ${majeur.last_name} | `;
    }

    isNew ? (title += "Ajouter") : (title += "Modifier");

    const onSubmitSuivi = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const newSuivi = {
            date: suivi_date,
            hours: parseInt(hour) || 0,
            minutes: parseInt(minute) || 0,
            label: capitalizeFirst(object.trim()),
            content: text || "",
            tags: tags,
            nb_km: parseInt(km) || 0,
        };

        if (!isNew) {
            editSuivi(addToast, suivisId, { suivis: newSuivi })
                .then(({ data }) => {
                    addToast("Le suivi a bien été modifié", {
                        appearance: "success",
                    });
                    history.push(`/single_suivi/${suivisId}`);
                })
                .catch();
        } else {
            createSuivi(addToast, majeurId, { suivis: newSuivi })
                .then(({ data }) => {
                    addToast("Le suivi a bien été créé", {
                        appearance: "success",
                    });
                    history.push(`/single_majeur/${majeurId}/suivis`);
                })
                .catch();
        }
    };

    // Add or ...
    const addFilter = (element: any) => {
        let newTags = tags;
        newTags.push(element.itemData._id);
        setTags(newTags);
    };

    // ... Remove filter for exportation of PA list
    const removeFilter = (element: any) => {
        let newTags = tags;
        newTags.splice(newTags.indexOf(element.itemData._id), 1);
        setTags(newTags);
    };

    return (
        <div className="form_suivi">
            <ArrowBack title={`${title} un suivi`} />
            <div className="page_content">
                <form onSubmit={onSubmitSuivi} className="column">
                    <div className="row layout-xs-column">
                        <InputDatePicker
                            id="suivi_date"
                            label="Date"
                            inputDate={suivi_date}
                            onChange={(newdate: Date) => setSuiviDate(newdate)}
                        />
                        <div className="flex005 hide-xs" />
                        <div className="row flex-1">
                            <Select
                                id="hours"
                                className="flex-1"
                                label="Nb heures"
                                value={hour}
                                options={hoursOptions}
                                onChange={(e) => setHour(e.target.value)}
                            />
                            <div className="flex005 hide-xs" />
                            <Select
                                id="minutes"
                                className="flex-1"
                                label="Nb minutes"
                                value={minute}
                                options={minutesOptions}
                                onChange={(e) => setMinute(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row layout-xs-column">
                        <InputComponent
                            id="objet"
                            className="my-input"
                            label="Objet"
                            required
                            value={object}
                            onChange={(e) => setObject(e.target.value)}
                            defaultValue={"Objet de la visite"}
                        />
                        <div
                            className="flex005 hide-xs"
                            style={{ marginLeft: "1.3%", marginRight: "1.3%" }}
                        />
                        {/* <div className="my-input-container"> */}
                        <div className="my-input-container">
                            <label>Tags :</label>
                            <MultiSelectComponent
                                id="mtselement"
                                cssClass="multiselect"
                                popupHeight="150px"
                                value={tags}
                                fields={fields}
                                dataSource={tagsOptions}
                                placeholder="Sélectionnez les tags..."
                                removed={(e) => removeFilter(e)}
                                select={(e) => addFilter(e)}
                            />
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="my-input-container">
                        <div className={"row"}>
                            <label
                                className={"layout-center-center"}
                                htmlFor="tool-bar"
                            >
                                Compte rendu :{" "}
                            </label>
                            <div className={"layout-center-center"}>
                                <SpeechInput text={text} setText={setText} />
                            </div>
                        </div>

                        <ReactQuill
                            theme="snow"
                            value={text}
                            onChange={setText}
                            modules={modules}
                            style={{
                                backgroundColor: "#fff",
                                color: "#000",
                                minHeight: 300,
                            }}
                        />
                    </div>
                    <div className="row flex-1" style={{ marginTop: 10 }}>
                        <div className="row flex-1" />
                        <div className="row flex-1">
                            <div className="row flex-1" />
                            <InputComponent
                                id="nbkm"
                                className="flex-1"
                                type="text"
                                onBlur={() => {
                                    let value = String(parseFloat(km));
                                    setKm(value !== "NaN" ? value : "0");
                                }}
                                label="Nb de Km"
                                value={km}
                                onChange={(e) => {
                                    let value = String(
                                        parseFloat(e.target.value)
                                    );
                                    setKm(value !== "NaN" ? value : "0");
                                }}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="valid-button"
                        style={{ marginTop: 10 }}
                    >
                        {isNew ? "Ajouter" : "Modifier"}
                    </button>
                </form>
            </div>
        </div>
    );
};
export default CreateUpdateSuivi;
