import axios from "axios";


const client = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

export const request = ({ ...options }) => {

    client.defaults.headers.common.Authorization = `Bearer token`
    const onSuccess = (response: any) => response
    const onError = (error: any) => {
        if (error?.response?.status === 401) {
            window.location.href = '/login'
        }

        if (![201, 200].includes(error?.response?.status) && options.addToast) {
            if (error.response && error.response.data && error.response.data.message) {
                options.addToast(error.response.data.message, { appearance: "error" });
            } else {
                console.error("Erreur sans réponse:", error);
                options.addToast("Une erreur est survenue", { appearance: "error" });
            }
        }

        throw error;
    }

    return client(options).then(onSuccess).catch(onError)
}
