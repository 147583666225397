import { request } from "../utils/axios-utils";

const namespace = "/api";

export const getContacts = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/contacts",
        method: "get",
    });
};
