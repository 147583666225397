import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    addMandatToControlledUser,
    getControlFromUsers,
} from "../../../_api/Mandat";
import { AlertMessage } from "../../../_shared/AlertMessage";
import { Button } from "../../../_shared/Button";
import { InputForm } from "../../../_shared/InputField";
import { useAuth } from "../Login/auth.context";

const ControlSelection = ({
    validHash,
    currentControls,
    isProtected,
    setCurrentControls,
}) => {
    const history = useHistory();

    const [newHash, setHash] = useState("");
    const { currentUser, setCurrentUser } = useAuth();

    if (!validHash) return null;

    const { addToast } = useToasts();
    const controls = currentControls.filter((control) => {
        return ["WAITING_DEPOT", "REVISION"].includes(control.status);
    });

    return (
        <div
            className={
                isProtected
                    ? "w-full flex justify-center items-center"
                    : "w-1/2 flex justify-center items-center"
            }
        >
            <div className="bg-white p-10 rounded-lg shadow-md w-1/2">
                {isProtected && (
                    <>
                        <InputForm
                            name="Ajouter un contrôle (code reçu par courrier)"
                            set={setHash}
                            value={newHash}
                        />{" "}
                        <div className="flex justify-end">
                            <Button
                                action={() =>
                                    addMandatToControlledUser(addToast, {
                                        hash: newHash,
                                    }).then((resp) => {
                                        setCurrentUser(resp.data.user);
                                        addToast(resp.data.message, {
                                            appearance: resp.data.type,
                                        });
                                        setHash("");
                                        getControlFromUsers(
                                            addToast,
                                            resp.data.user.crg_infos.mandat_id
                                        ).then((resp) => {
                                            setCurrentControls(resp.data);
                                        });
                                    })
                                }
                                name={"Ajouter le contrôle"}
                            />
                        </div>
                    </>
                )}
                <hr className="mt-4 mb-4" />
                <div className="space-y-4 mt-4">
                    <h3 className="text-center">
                        Choisir le contrôle à réaliser :
                    </h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                        {controls.map((control) => {
                            return (
                                <div
                                    key={control._id}
                                    className="cursor-pointer bg-white shadow-lg rounded-lg border border-gray-200"
                                    onClick={() =>
                                        history.push(
                                            `/verification/${control._id}`
                                        )
                                    }
                                >
                                    <div className="mt-2 bg-blue-50 border border-blue-300 rounded-md p-4">
                                        <p className="text-center text-lg font-semibold text-blue-900">
                                            {control.nom} -{" "}
                                            {new Date(
                                                control.start_period
                                            ).toLocaleDateString()}{" "}
                                            -{" "}
                                            {new Date(
                                                control.end_period
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {controls.length === 0 && (
                        <AlertMessage
                            type="info"
                            message="Il n'y a aucun contrôle en attente de dépot."
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ControlSelection;
